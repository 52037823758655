import React, { useState, useEffect } from "react";
import CSVReader from "react-csv-reader";
import { Modal, Row, Col } from "react-bootstrap";
import Select from "react-select";

import {
  customToast,
  createMappedArray,
  handleColumnMapping,
  handleFileLoaded,
} from "../../../shared/utility";
import Button from "../../../shared/components/Buttons/Button";
import { UploadCsvIcon } from "../../../assets/icons/iconsProvider";
import {
  toastType,
  toastMessages,
  globalConstants,
  buttonNameConstants,
  buttonTypeConstants,
  IMPORTED_FROM_FILE,
} from "../../../constants";
import { importSelectFieldDropdownStyle } from "../../../assets/scss/style";
import {
  emailRegex,
  cleanImportPhoneRegex,
  importPhoneRegex,
} from "../../../shared/validationSchema/validationSchema";

const ImportCustomer = (props) => {
  const {
    currentStore,
    duplicateCustomers,
    bulkUploadCustomers,
    importCustomerModal,
    emptyDuplicateRecords,
    toggleImportCustomerModal,
  } = props;

  //-------useState
  const [columns, setColumns] = useState([]);
  const [fileInputKey, setFileInputKey] = useState(Date.now());
  const [defaultSortFieldId, setDefaultSortFieldId] = useState();
  const [invalidCsvRecords, setInvalidCsvRecords] = useState([]);
  const [csvData, setCsvData] = useState("");
  const [columnMapping, setColumnMapping] = useState({});
  const predefinedColumns = [
    "First Name",
    "Last Name",
    "Email",
    "Phone Number",
    "Driver's License",
    "Street 1",
    "Street 2",
    "City",
    "State",
    "Zip",
    "Customer Credit",
  ];
  const requiredColumns = ["First Name", "Last Name"];

  //-------handle Hide Modal
  const handleHideModal = () => {
    toggleImportCustomerModal();
    setTimeout(() => {
      emptyDuplicateRecords();
      setInvalidCsvRecords("");
      setCsvData("");
      setColumnMapping({});
    }, 500);
  };

  // validate csv data
  const validateCsvData = (customers) => {
    let validData = [];
    let invalidData = [];

    // for each row email or phone must be provided
    let errors = [];
    customers.forEach((rowData) => {
      errors = [];
      // email or phone is required
      if (
        (rowData["Email"] === globalConstants.EMPTY_STRING ||
          rowData["Email"] === " ") &&
        (rowData["Phone Number"] === globalConstants.EMPTY_STRING ||
          rowData["Phone Number"] === " ")
      ) {
        errors.push("Email & Phone both cannot be empty.");
      }
      // required field is empty (first name)
      if (
        rowData["First Name"] === globalConstants.EMPTY_STRING ||
        rowData["First Name"] === " "
      ) {
        errors.push("Invalid/missing First Name value.");
      }
      // required field is empty (last name)
      if (
        rowData["Last Name"] === globalConstants.EMPTY_STRING ||
        rowData["Last Name"] === " "
      ) {
        errors.push("Invalid/missing Last Name value.");
      }
      // required field is invalid (email)
      if (
        rowData["Email"] !== globalConstants.EMPTY_STRING &&
        rowData["Email"] !== " " &&
        !emailRegex.test(rowData["Email"])
      ) {
        errors.push("Invalid/missing Email value.");
      }
      // required field is invalid (phone number)
      if (
        rowData["Phone Number"] !== globalConstants.EMPTY_STRING &&
        rowData["Phone Number"] !== " " &&
        !importPhoneRegex.test(rowData["Phone Number"])
      ) {
        errors.push("Invalid/missing Phone Number value.");
      }

      // adding rejection reasons if any
      if (errors.length) {
        invalidData.push({
          ...rowData,
          ["Rejection Reason"]: errors.join(" | "),
        });
      } else {
        validData.push(rowData);
      }
    });
    customers = validData;
    validData = [];

    return {
      validData: customers,
      invalidData,
    };
  };

  // clean csv data
  const cleanCsvData = (customers) => {
    // converting customer credit to 0 if not provided
    customers.forEach((rowData) => {
      if (!rowData["Customer Credit"] || isNaN(rowData["Customer Credit"])) {
        rowData["Customer Credit"] = 0;
      }
      rowData["Phone Number"] = String(rowData["Phone Number"]).replace(
        cleanImportPhoneRegex,
        ""
      );
    });
    // no return as it is pass by reference and updating the original array
  };

  const handleStartImport = () => {
    // either email or phone must be selected
    if (
      !columnMapping.hasOwnProperty("Email") &&
      !columnMapping.hasOwnProperty("Phone Number")
    ) {
      customToast("Email or phone number is required.", toastType.ERROR);
      return;
    }
    const arrayAfterMapping = createMappedArray(
      csvData,
      predefinedColumns,
      columnMapping
    );
    // validate and clean csv data
    let validateCsvDataRes = validateCsvData(arrayAfterMapping);
    cleanCsvData(validateCsvDataRes.validData);

    //-------api call to uploade the csv records
    validateCsvDataRes.validData = validateCsvDataRes.validData.map(
      (customer) => ({
        firstName: customer["First Name"],
        lastName: customer["Last Name"],
        email: customer["Email"] || globalConstants.EMPTY_STRING,
        mobile: customer["Phone Number"] || globalConstants.EMPTY_STRING,
        driverLicense:
          customer["Driver's License"] || globalConstants.EMPTY_STRING,
        line1: customer["Street 1"] || globalConstants.EMPTY_STRING,
        line2: customer["Street 2"] || globalConstants.EMPTY_STRING,
        city: customer["City"] || globalConstants.EMPTY_STRING,
        state: customer["State"] || globalConstants.EMPTY_STRING,
        zip: customer["Zip"] || globalConstants.EMPTY_STRING,
        currentBalance: customer["Customer Credit"] || 0,
        isSmsAlert: false,
        isEmailAlert: false,
        productNotification: [],
        store: {
          id: currentStore?.id,
          name: currentStore?.storeName,
        },
        companyId: currentStore?.companyId,
        companyCustomerId: 0,
      })
    );
    bulkUploadCustomers(currentStore?.companyId, currentStore?.id, {
      validateCsvDataRes,
      handleHideModal,
    });
  };

  //-------duplicate records find from backend
  useEffect(() => {
    if (duplicateCustomers?.length > 0) {
      toggleImportCustomerModal();
      setInvalidCsvRecords(duplicateCustomers);
    }
  }, [duplicateCustomers]);

  return (
    <Modal
      show={importCustomerModal}
      size={csvData ? "lg" : "md"}
      onHide={handleHideModal}
      animation={true}
      centered
      backdrop="static"
      className="add-inventory-modal"
    >
      <Modal.Header closeButton className="add-inventory-modal-header">
        <Modal.Title>
          <span className="add-inventory-modal-header-name">
            Import Customers
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="add-inventory-modal-body ">
        {csvData ? (
          <>
            <p className="p-0 m-0">* = Required Field</p>
            <Row className="m-0 px-0">
              <div className="column-mapping-table-wrapper container-scrollbar">
                <Row className="m-0 py-3 border-bottom px-0 import-inventory-column-header-wrapper">
                  <Col md={6} className="d-flex align-items-center">
                    <span className="import-inventory-columns-heading">
                      Inventory Columns
                    </span>
                  </Col>
                  <Col md={6} className="d-flex align-items-center">
                    <span className="import-inventory-columns-heading">
                      Your Column Header
                    </span>
                  </Col>
                </Row>
                {predefinedColumns.map((predefinedColumn, index) => (
                  <Row key={index} className="m-0 py-2 border-bottom px-0">
                    <Col md={6} className="d-flex align-items-center">
                      <span className="predefinded-column-label">
                        {predefinedColumn}
                        {requiredColumns.includes(predefinedColumn) && "*"}
                      </span>
                    </Col>
                    <Col
                      md={6}
                      className="d-flex justify-content-start align-items-center"
                    >
                      <Select
                        options={Object.keys(csvData[0]).map((csvColumn) => ({
                          value: csvColumn,
                          label: csvColumn,
                        }))}
                        value={{
                          value: columnMapping[predefinedColumn] || "",
                          label:
                            columnMapping[predefinedColumn] || "Select Column",
                        }}
                        onChange={(selectedOption) =>
                          handleColumnMapping(
                            predefinedColumn,
                            selectedOption.value,
                            setColumnMapping
                          )
                        }
                        styles={importSelectFieldDropdownStyle}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        className="w-100"
                      />
                    </Col>
                  </Row>
                ))}
              </div>
              <Col
                md={12}
                className="mt-4 d-flex justify-content-end align-items-center gap-3 px-0"
              >
                <Button
                  type="button"
                  label={buttonNameConstants.BACK}
                  buttonType={buttonTypeConstants.GHOST_BUTTON}
                  handleClick={() => setCsvData("")}
                />
                <Button
                  type="button"
                  label={buttonNameConstants.START_IMPORT}
                  handleClick={() => handleStartImport()}
                  isDisabled={requiredColumns.find((col) => {
                    return !columnMapping.hasOwnProperty(col);
                  })}
                />
              </Col>
            </Row>
          </>
        ) : (
          <Row className="m-0">
            <Col
              md={12}
              className="d-flex justify-content-center align-items-center"
            >
              <label
                className="d-flex flex-column justify-content-center align-items-center gap-2 upload-csv-wrapper"
                style={{ height: "fit-content" }}
              >
                <UploadCsvIcon />
                Click or drag file to this area to upload
                <CSVReader
                  inputId="CSVReader"
                  key={fileInputKey}
                  inputStyle={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    height: "100%",
                    width: "100%",
                    cursor: "pointer",
                    opacity: 0,
                  }}
                  onFileLoaded={(data, fileInfo) =>
                    handleFileLoaded(
                      data,
                      fileInfo,
                      predefinedColumns,
                      setCsvData,
                      setColumnMapping
                    )
                  }
                  parserOptions={{ header: true, skipEmptyLines: true }}
                  accept=".csv"
                />
              </label>
            </Col>
            <Col md={7}></Col>
            <Col
              md={5}
              className="mt-3 d-flex justify-content-end align-items-center"
            >
              <Button
                type="button"
                label={buttonNameConstants.CANCEL}
                buttonType={buttonTypeConstants.GHOST_BUTTON}
                handleClick={() => handleHideModal()}
              />
            </Col>
          </Row>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ImportCustomer;
