import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";

import Button from "../../../../shared/components/Buttons/Button";
import {
  buttonNameConstants,
  buttonTypeConstants,
  inventoryConstants,
  toastMessages,
  toastType,
  transactionConstants,
} from "../../../../constants";
import SimpleNumberField from "../../../../shared/components/inputNumberField/SimpleNumberField";
import {
  CloseIcon,
  DollarIcon,
  TickIconLong,
} from "../../../../assets/icons/iconsProvider";
import {
  customToast,
  isSpinnerEnabled,
  parseToThousandSeperatorDecimalNumber,
} from "../../../../shared/utility";
import InventoryOverviewThreeCards from "../../../../shared/components/inventoryOverviewThreeCards/InventoryOverviewThreeCards";

const AddBatchInventoryFooter = ({
  closeModal,
  data,
  handleCogsValueChange,
  cogsPrice,
  spinnerArray,
  handleResetBatch,
  ...props
}) => {
  const [isEditTotalCost, setIsEditTotalCost] = useState(false);
  const [totalCost, setTotalCost] = useState(0);
  const hanldeEditTotalCost = () => {
    setIsEditTotalCost(!isEditTotalCost);
    setTotalCost(data.totalCost);
  };
  const handleCogsChange = () => {
    if (totalCost > 0) {
      handleCogsValueChange(totalCost);
      hanldeEditTotalCost();
    } else {
      customToast(toastMessages.TOTAL_COST, toastType.ERROR);
      hanldeEditTotalCost();
      setTotalCost(data.totalCost);
    }
  };
  const setTotalCostValue = (inputValue) => {
    setTotalCost(inputValue);
  };
  useEffect(() => {
    setTotalCost(data.totalCost);
  }, [data.totalCost]);

  return (
    <>
      <Row className="m-0 mb-3 px-0">
        <Col md={6} className="ps-0">
          <div className="inventory-batch-card batch-total-card d-flex flex-row justify-content-between align-items-center p-3">
            <p className="card-heading p-0 m-0">Total Cost: </p>

            {!isEditTotalCost ? (
              <div className="d-flex gap-3">
                <div className="dashboard-card-wrapper  p-3">
                  <div
                    md={12}
                    className="card-wrapper-wallet d-flex flex-column justify-content-center align-items-start p-0"
                  >
                    <span>
                      $
                      {parseToThousandSeperatorDecimalNumber(
                        data.totalCost || 0
                      )}
                    </span>
                  </div>
                </div>
                {totalCost ? (
                  <>
                    <button
                      type="button"
                      className="edit-budget-button p-0"
                      onClick={() => hanldeEditTotalCost()}
                    >
                      Edit
                    </button>{" "}
                    <button
                      type="button"
                      className="edit-budget-button p-0"
                      onClick={() => handleResetBatch()}
                    >
                      Reset
                    </button>
                  </>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <div className="d-flex gap-1 p-1 pe-0">
                <SimpleNumberField
                  // disabled={true}
                  name="cashOffer"
                  placeHolder="0"
                  className="add-inventory-price-field input-background"
                  IconImage={DollarIcon}
                  value={totalCost}
                  onChange={setTotalCostValue}
                />

                <div className="w-100 d-flex justify-content-center align-content-center ">
                  <button
                    type="button"
                    className="edit-buttons pe-0"
                    onClick={() => hanldeEditTotalCost()}
                  >
                    <CloseIcon className="close-button-purchasing-power" />
                  </button>
                  <button
                    onClick={() => handleCogsChange()}
                    type="submit"
                    className="edit-buttons  pe-0"
                  >
                    <TickIconLong className="close-button-purchasing-power" />
                  </button>
                </div>
              </div>
            )}
          </div>
        </Col>
        <Col md={6} className="pe-0">
          <InventoryOverviewThreeCards
            data={[
              { label: "Total Items", value: data.totalItems },
              {
                label: "Total Market Value",
                value:
                  "$" +
                  parseToThousandSeperatorDecimalNumber(
                    data.totalMarketPrice ?? 0
                  ),
              },
              {
                label: "Total Price",
                value:
                  "$" + parseToThousandSeperatorDecimalNumber(data.totalPrice),
              },
            ]}
          />
        </Col>
      </Row>

      <Row className="m-0">
        <Col md={6} className="d-flex justify-content-end align-items-center">
          <Button
            label={buttonNameConstants.CANCEL}
            className="w-50"
            type="button"
            handleClick={() => closeModal()}
            buttonType={buttonTypeConstants.GHOST_BUTTON}
            isDisabled={isSpinnerEnabled(spinnerArray, [
              inventoryConstants.ADD_INVENTORY,
              transactionConstants.ADD_TRADE_BATCH_ITEM,
            ])}
          />
        </Col>
        <Col md={6} className="d-flex align-items-center">
          <Button
            label={buttonNameConstants.ADD_TO_INVENTORY}
            className="w-50"
            type="submit"
            handleClick={props.handleSubmitAddBatchInventory}
            isDisabled={
              !Number(data.totalItems) ||
              isSpinnerEnabled(spinnerArray, [
                inventoryConstants.ADD_INVENTORY,
                transactionConstants.ADD_TRADE_BATCH_ITEM,
              ])
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default AddBatchInventoryFooter;
