import { Form, Formik } from "formik";
import { connect } from "react-redux";
import React, { useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { useDebouncedCallback } from "use-debounce";

import {
  customToast,
  LoadingIndicator,
  parseToThousandSeperatorDecimalNumber,
} from "../../../../shared/utility";
import Button from "../../../../shared/components/Buttons/Button";
import {
  PRODUCT_CONDITIONS_ENUMS,
  PRODUCT_TYPES_ENUMS,
} from "../../../../system/globalEnums";
import { transactionActions, systemActions } from "../../../../redux/actions";
import SearchField from "../../../../shared/components/searchField/SearchField";
import InputNumberField from "../../../../shared/components/inputNumberField/InputNumberField";
import { addGiftCardValidationSchema } from "../../../../shared/validationSchema/validationSchema";
import {
  toastType,
  globalConstants,
  buttonNameConstants,
  buttonTypeConstants,
  transactionConstants,
} from "../../../../constants";

const AddGiftCard = (props) => {
  const {
    currentStore,
    giftCardModal,
    addItemInCart,
    giftCardDetails,
    getGiftCardDetails,
    toggleGiftCardModal,
    emptyGiftCardDetails,
  } = props;

  const initialValues = {
    amountToLoad: 0,
  };

  const getGiftCard = async () => {
    getGiftCardDetails(currentStore?.id, giftCardItem.barcode);
  };
  const debouncedMakeHttpRequest = useDebouncedCallback(getGiftCard, 1500);

  const [giftCardItem, setGiftCardItem] = useState({
    barcode: globalConstants.EMPTY_STRING,
  });

  //-------handle hide modal
  const handleHideModal = () => {
    toggleGiftCardModal();
    setGiftCardItem({
      barcode: globalConstants.EMPTY_STRING,
    });
    emptyGiftCardDetails();
  };

  const handleSearchFieldChange = (value) => {
    if (value.length >= transactionConstants.GC_BARCODE_MIN_LENGTH) {
      debouncedMakeHttpRequest();
    }
    setGiftCardItem({ ...giftCardItem, barcode: value });
  };

  const handleAddGiftCard = (values) => {
    if (!giftCardItem.barcode) {
      customToast(
        transactionConstants.NO_GIFT_CARD_IS_SELECTED,
        toastType.ERROR
      );
      return;
    } else if (
      giftCardItem.barcode.length < transactionConstants.GC_BARCODE_MIN_LENGTH
    ) {
      customToast(
        transactionConstants.GC_BARCODE_LENGTH_ERROR,
        toastType.ERROR
      );
      return;
    }

    addItemInCart({
      id: globalConstants.EMPTY_STRING,
      item: `${transactionConstants.GIFT_CARD_ITEM}  (${giftCardItem.barcode})`,
      itemName: `${transactionConstants.GIFT_CARD_ITEM}  (${giftCardItem.barcode})`,
      max: 1,
      quantity: 1,
      category: PRODUCT_TYPES_ENUMS.GIFT_CARD,
      productType: PRODUCT_TYPES_ENUMS.OTHER,
      sku: [giftCardItem.barcode],
      cogs: 0,
      price: values.amountToLoad,
      tcgPlayerUrl: globalConstants.EMPTY_STRING,
      epid: globalConstants.EMPTY_STRING,
      tags: [],
      description: globalConstants.EMPTY_STRING,
      type: PRODUCT_CONDITIONS_ENUMS.COMPLETE,
      subcategory: globalConstants.EMPTY_STRING,
      isToggleDiscount: false,
      isSingleItemDiscountField: false,
    });
    handleHideModal();
  };

  return (
    <Modal
      show={giftCardModal}
      size="md"
      onHide={handleHideModal}
      animation={true}
      centered
      backdrop="static"
      className="add-inventory-modal"
    >
      <Modal.Header closeButton className="add-inventory-modal-header">
        <Modal.Title>
          <span className="add-inventory-modal-header-name">
            Sell/Load Gift Card
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="add-inventory-modal-body ">
        <Row className="m-0">
          {/* <div>
            <BarcodeReader onError={handleError} onScan={handleScan} />
          </div> */}
          <Col md={12} className="mb-4">
            <SearchField
              value={giftCardItem.barcode}
              placeHolder="Enter Barcode"
              onChange={(e) => handleSearchFieldChange(e.target.value)}
              LoadingIndicator={
                giftCardDetails?.isLoading ? LoadingIndicator : false
              }
            />
          </Col>
          <Col
            md={12}
            className="d-flex justify-content-between align-items-center mb-4 balance-wrapper"
          >
            <span>Balance</span>
            <span>
              $
              {giftCardDetails?.giftCardBalance
                ? parseToThousandSeperatorDecimalNumber(
                    giftCardDetails?.giftCardBalance
                  )
                : "0.00"}
            </span>
          </Col>
          <Formik
            initialValues={initialValues}
            validate={addGiftCardValidationSchema}
            onSubmit={handleAddGiftCard}
          >
            {({ resetForm }) => (
              <Form>
                <Col
                  md={12}
                  className="mb-3 d-flex justify-content-between align-items-center"
                >
                  <span className="opacity-50">Amount to Load:</span>
                  <InputNumberField
                    name="amountToLoad"
                    placeHolder="0.00"
                    className="discount-number-field gift-card-amount-field"
                  />
                </Col>
                <Col md={12} className="mb-3 d-flex gap-2">
                  <Button
                    type="button"
                    label={buttonNameConstants.CANCEL}
                    handleClick={() => {
                      handleHideModal();
                      resetForm();
                    }}
                    buttonType={buttonTypeConstants.GHOST_BUTTON}
                    className="w-100"
                  ></Button>
                  <Button
                    type="submit"
                    className="w-100"
                    label={buttonNameConstants.ADD}
                    isDisabled={giftCardDetails?.isLoading ?? false}
                  ></Button>
                </Col>
              </Form>
            )}
          </Formik>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  currentStore: state.store.currentStore,
  giftCardDetails: state.transaction.giftCardDetails,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  activateSpinner: (data) => dispatch(systemActions.activateSpinner(data)),
  deactivateSpinner: (data) => dispatch(systemActions.deactivateSpinner(data)),
  getGiftCardDetails: (storeId, barcode) =>
    dispatch(transactionActions.getGiftCardDetails(storeId, barcode)),
  emptyGiftCardDetails: () =>
    dispatch(transactionActions.emptyGiftCardDetails()),
});

//-------Export transaction Component
export default connect(mapStateToProps, mapDispatchToProps)(AddGiftCard);
