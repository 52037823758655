import { Form, Formik } from "formik";
import { connect } from "react-redux";
import { Col, Modal, Row } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";

import { systemActions } from "../../../../redux/actions";
import Button from "../../../../shared/components/Buttons/Button";
import ProductImage from "../../../../assets/images/productimage.png";
import ProductTagList from "../../../../shared/components/tags/ProductTagList";
import CheckboxField from "../../../../shared/components/checkBox/CheckboxField";
import InputNumberField from "../../../../shared/components/inputNumberField/InputNumberField";
import CategoriesDropdown from "../../../../shared/components/selectDropdown/CategoriesDropdown";
import { addNewTradeValidationSchema } from "../../../../shared/validationSchema/validationSchema";
import {
  PRODUCT_TYPES_ENUMS,
  PRODUCT_CONDITIONS_ENUMS,
  TRADING_CARD_CATEGORIES_ENUMS,
} from "../../../../system/globalEnums";
import {
  DollarIcon,
  SidebarToggleIcon,
  LogoImageReplaceIcon,
} from "../../../../assets/icons/iconsProvider";
import {
  isTagsEqual,
  customToast,
  handleTagList,
  getShortestSku,
  handleMarginChange,
  getPresetStockPrice,
  handleViewTCGPlayer,
  handleViewEbaySales,
  parseToDecimalNumber,
  getMarginDropdownValue,
  handleViewPriceCharting,
  uploadCustomProductImage,
  handleEditBatchConditionChange,
  batchEditChangePriceBaseOnTags,
  parseToThousandSeperatorDecimalNumber,
  handleRaritiesChangeInSingleAddEdit as handleRaritiesChange,
  handleUploadProductImage,
} from "../../../../shared/utility";
import {
  toastType,
  EMPTY_STRING,
  inventoryConstants,
  buttonNameConstants,
  buttonTypeConstants,
  categoryDropdownConstants,
  IMAGE_FILE_TYPES,
} from "../../../../constants";

const BatchTradeEdit = (props) => {
  //-------Props
  const {
    addToCart,
    inventory,
    tradeDiscount,
    activateSpinner,
    deactivateSpinner,
    editProductObject,
    isBatchProductEditModal,
    toggleIsBatchProductEditModal,
  } = props;

  //-------inventory reducer
  const { productTags } = inventory;

  //-------tradeDiscount Reducer
  const { markupPresets, tradeinMargin } = tradeDiscount;
  //------UseRef
  const hiddenFileInput = useRef(null);

  //-------useState
  const [margin, setMargin] = useState(EMPTY_STRING);
  const [selectedTags, setSelectedTags] = useState([]);
  const [addSelectedTags, setAddSelectedTags] = useState([]);
  const [productUrl, setProductUrl] = useState(EMPTY_STRING);
  const [productDetail, setProductDetail] = useState(EMPTY_STRING);
  const [initialGlobalRatio, setInitialGlobalRatio] = useState(EMPTY_STRING);
  const [rarities, setRarities] = useState(editProductObject?.raritiesList);
  const [productDataObject, setProductDataObject] = useState(editProductObject);
  const [marginTypeObject, setMarginTypeObject] = useState(
    editProductObject?.tradeInMarginTypeObject || {}
  );
  const [isTradiingCardWarningText, setIsTradiingCardWarningText] =
    useState(false);
  const [productTagsList, setProductTagsList] = useState(
    handleTagList(
      productTags,
      productDataObject?.genre,
      editProductObject?.productCondition,
      inventoryConstants.NINTENDO_CATEGORIES.includes(
        productDataObject?.consoleName
      ) ||
        inventoryConstants.SKYLANDER_CATEGORIES.includes(
          productDataObject?.consoleName
        )
        ? editProductObject?.productCondition ===
          PRODUCT_CONDITIONS_ENUMS.COMPLETE
          ? inventoryConstants.PRODUCT_TAGS.MISSING_MANUAL
          : inventoryConstants.PRODUCT_TAGS.WITH_MANUAL
        : ""
    ) || []
  );
  const [productType, setProductType] = useState(
    editProductObject?.productType
  );
  const [selectedRarity, setSelectedRarity] = useState(
    editProductObject?.rarity
  );
  const [productCondition, setProductCondition] = useState(
    editProductObject?.productCondition
      ? {
          value: editProductObject?.productCondition,
          label: editProductObject?.productCondition,
        }
      : {}
  );

  //-------Initial Values
  const initialValues = {
    quantity: editProductObject?.inStockQuantity || 1,
    box:
      productCondition?.value === PRODUCT_CONDITIONS_ENUMS.COMPLETE &&
      selectedTags?.includes(inventoryConstants.PRODUCT_TAGS.WITH_BOX),
    manual:
      (productCondition?.value === PRODUCT_CONDITIONS_ENUMS.COMPLETE &&
        !selectedTags?.includes(
          inventoryConstants.PRODUCT_TAGS.MISSING_MANUAL
        )) ||
      (productCondition?.value === PRODUCT_CONDITIONS_ENUMS.LOOSE &&
        selectedTags?.includes(inventoryConstants.PRODUCT_TAGS.WITH_MANUAL)),
    cables:
      (productCondition?.value === PRODUCT_CONDITIONS_ENUMS.COMPLETE ||
        productCondition?.value === PRODUCT_CONDITIONS_ENUMS.LOOSE) &&
      !selectedTags?.includes(inventoryConstants.PRODUCT_TAGS.MISSING_WIRES),
    controller:
      (productCondition?.value === PRODUCT_CONDITIONS_ENUMS.COMPLETE ||
        productCondition?.value === PRODUCT_CONDITIONS_ENUMS.LOOSE) &&
      !selectedTags?.includes(
        inventoryConstants.PRODUCT_TAGS.MISSING_CONTROLLER
      ),
    costOfGoods: productDataObject?.costOfGoods || EMPTY_STRING,
    inStockPrice: productDataObject?.inStockPrice || EMPTY_STRING,
    cashOffer: productDataObject?.cashOffer || EMPTY_STRING,
    tradeOffer: productDataObject?.tradeOffer || EMPTY_STRING,
  };

  //-------Functions
  //-------Handle Hide Modal
  const handleHideModal = () => {
    toggleIsBatchProductEditModal();
  };

  //-------Handle Replace Image Button Click
  const handleReplaceButtonClick = () => {
    hiddenFileInput.current.click();
  };

  //-------Handle Submit
  const handleSubmit = (values) => {
    if (!productDataObject?.productName) {
      customToast("Please select some product", toastType.ERROR);
    } else {
      const sendToData = {
        ...productDataObject,
        id: productDataObject?.id,
        productId: productDataObject.productId,
        productName: productDataObject.productName,
        consoleName: productDataObject.consoleName,
        marketPrice: productDataObject.marketPrice,
        suggestedSellPrice: productDataObject.suggestedSellPrice,
        costOfGoods: values.costOfGoods,
        inStockPrice: values.inStockPrice,
        inStockQuantity: values.quantity,
        skuNumber: 0,
        gameStopBuyPrice: productDataObject.gameStopBuyPrice,
        averageBuyPrice: productDataObject.averageBuyPrice,
        averageSellPrice: productDataObject.averageSellPrice,
        maxBuyPrice: productDataObject.maxBuyPrice,
        imgUrl: productDataObject?.imgUrl,
        productCondition: productCondition?.value,
        rarity: selectedRarity || [],
        tags: addSelectedTags?.map((tag) => tag),
        tradeInMarginTypeObject: marginTypeObject,
        cashOffer: values.cashOffer,
        tradeOffer: values.tradeOffer,
      };
      addToCart(sendToData);
      handleHideModal();
    }
  };

  const handleCashOfferValues = (InputValue, setFieldValue) => {
    const newCashOffer = parseFloat(InputValue); // Assuming InputValue is the new cash offer value
    setFieldValue("cashOffer", newCashOffer);
    const calculateRatio =
      marginTypeObject?.marginObject?.tradeinMarginPercentage /
      marginTypeObject?.marginObject?.cashMarginPercentage;
    const newTradeValue = newCashOffer * calculateRatio;
    setFieldValue("tradeOffer", parseToDecimalNumber(newTradeValue, true));
  };

  const handleTradeOfferValues = (InputValue, setFieldValue) => {
    const newTradeValue = parseFloat(InputValue);
    setFieldValue("tradeOffer", newTradeValue);
    const calculateRatio =
      marginTypeObject?.marginObject?.tradeinMarginPercentage /
      marginTypeObject?.marginObject?.cashMarginPercentage;
    const newCashOffer = newTradeValue / calculateRatio;
    setFieldValue("cashOffer", parseToDecimalNumber(newCashOffer, true));
  };

  //-------handle tag select or unselect
  const onSelectTagsChange = (selectedTags) => {
    const selectedTagList = selectedTags.map((tag) => tag);
    const selectedTagListTags = selectedTags.map((tag) => tag.label);
    batchEditChangePriceBaseOnTags(
      selectedTagList,
      productDataObject,
      setProductDataObject,
      productCondition,
      productType,
      tradeinMargin,
      markupPresets,
      true
    );

    setSelectedTags(selectedTagListTags);
    setAddSelectedTags(selectedTagList);
  };

  //------find product details
  const findInventoryProductDetail = (condition) => {
    if (editProductObject?.productsAvailableInInventory?.length > 0) {
      const findProduct = editProductObject?.productsAvailableInInventory?.find(
        (product) => {
          if (
            product.price.type === condition &&
            isTagsEqual(product.tags || [], selectedTags || [])
          ) {
            if (
              editProductObject?.apiSource === inventoryConstants.POKEMON_API ||
              editProductObject?.apiSource === inventoryConstants.SCRYFALL_API
            ) {
              if (product.cardRarity === selectedRarity.label) {
                return product;
              }
            } else {
              return product;
            }
          }
        }
      );
      if (findProduct) {
        setProductDataObject({
          ...productDataObject,
          inStoreQuantity: findProduct?.price?.quantity || 0,
          skuNumber: findProduct?.sku || 0,
          inStorePrice: findProduct?.price?.unit_sell_price || 0,
          costOfGoods: findProduct?.price?.unit_purchase_price || 0,
          averageBuyPrice: findProduct?.price?.averageBuyPrice || 0,
          averageSellPrice: findProduct?.price?.averageSellPrice || 0,
          maxBuyPrice: findProduct?.price?.maxBuyPrice || 0,
        });
        return;
      }
    }
    setProductDataObject({
      ...productDataObject,
      inStoreQuantity: 0,
      skuNumber: EMPTY_STRING,
      inStorePrice: 0,
    });
  };

  //-------- handle custom margin
  const handleMarginSelectChange = (selectedOption, setFieldValue) => {
    setInitialGlobalRatio("");
    handleMarginChange(
      setMargin,
      productDataObject?.marketPrice,
      productDataObject?.productType,
      setFieldValue,
      selectedOption,
      tradeinMargin,
      productDataObject,
      setMarginTypeObject
    );
  };

  //-------useEffects
  useEffect(() => {
    if (productCondition?.value) {
      findInventoryProductDetail(productCondition?.value);
    }
    const tradingCardConditions = inventoryConstants.TRADING_CARD_CONDITION.map(
      (item) => item.value
    );
    if (
      tradingCardConditions.includes(productCondition?.value) &&
      productCondition?.value !== PRODUCT_CONDITIONS_ENUMS.NEAR_MINT
    ) {
      setIsTradiingCardWarningText(true);
    } else {
      setIsTradiingCardWarningText(false);
    }
  }, [selectedTags, selectedRarity, productCondition]);

  //-------Return batch Edit Component
  return (
    <Modal
      show={isBatchProductEditModal}
      size="lg"
      onHide={handleHideModal}
      animation={true}
      centered
      backdrop="static"
      className={`add-inventory-modal ${productUrl ? "iframe-opened" : ""}`}
    >
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={handleSubmit}
        validate={addNewTradeValidationSchema}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Modal.Header
              closeButton
              className="add-inventory-modal-header  pt-0 pb-1"
            >
              <Modal.Title>
                <span className="add-inventory-modal-header-name">
                  Edit Batch Inventory
                </span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="add-inventory-modal-body d-flex ">
              <Row className={`m-0 ${productUrl ? "w-50" : "w-100"}`}>
                <Col xs={12} className="add-inventory-hero-wrapper px-0">
                  <Row className="m-0">
                    <Col md={8} className="d-flex gap-3">
                      <div className="add-inventory-product-image">
                        <img src={productDataObject?.imgUrl || ProductImage} />
                        <input
                          type="file"
                          className="d-none"
                          accept=".png, .jpg, .jpeg"
                          onChange={(e) =>
                            handleUploadProductImage(
                              e,
                              activateSpinner,
                              deactivateSpinner,
                              productDataObject,
                              setProductDataObject
                            )
                          }
                          ref={hiddenFileInput}
                        />
                        <button
                          onClick={handleReplaceButtonClick}
                          type="button"
                          className="replace-logo-button"
                        >
                          <LogoImageReplaceIcon className="replace-icon" />
                        </button>
                      </div>

                      <div className="d-flex flex-column justify-content-between align-items-start product-content-wrapper">
                        <span>
                          {productDataObject.productName || "Product Name"}
                        </span>
                        <span>
                          {productDataObject.consoleName || "Console Name"}
                        </span>
                        <span>
                          {productDataObject?.skuNumber?.length
                            ? `#${getShortestSku(productDataObject?.skuNumber)}`
                            : "#Auto Generated"}
                        </span>
                        <span>{`In Stock: ${
                          productDataObject.inStoreQuantity || 0
                        }`}</span>
                      </div>
                    </Col>

                    {productDataObject?.apiSource ===
                    inventoryConstants.PRICECHARTING_API ? (
                      <Col
                        md={4}
                        className="d-flex flex-column align-items-center gap-2"
                      >
                        <Button
                          type="button"
                          label={buttonNameConstants.VIEW_EBAY_SALES}
                          className="w-100"
                          handleClick={() =>
                            handleViewEbaySales(
                              productDataObject?.productName,
                              productDataObject?.consoleName,
                              productDataObject?.genre
                            )
                          }
                          buttonType={buttonTypeConstants.LIGHT_PURPLE}
                        />
                        <Button
                          type="button"
                          label={buttonNameConstants.VIEW_PRICE_CHARTING}
                          className="w-100"
                          handleClick={() =>
                            handleViewPriceCharting(
                              {
                                productObject: {
                                  "console-name":
                                    productDataObject?.consoleName,
                                  "product-name":
                                    productDataObject?.productName,
                                },
                              },
                              setProductUrl
                            )
                          }
                          buttonType={buttonTypeConstants.LIGHT_PURPLE}
                        />
                      </Col>
                    ) : productDataObject?.apiSource ===
                        inventoryConstants.SCRYFALL_API ||
                      productDataObject?.apiSource ===
                        inventoryConstants.POKEMON_API ? (
                      <Col
                        md={4}
                        className="d-flex flex-column align-items-center gap-2"
                      >
                        <Button
                          type="button"
                          label={buttonNameConstants.VIEW_TCG_PLAYER}
                          className="w-100"
                          handleClick={() =>
                            handleViewTCGPlayer(productDataObject)
                          }
                          buttonType={buttonTypeConstants.LIGHT_PURPLE}
                          isDisabled={!productDataObject?.tcgPlayerUrl}
                        />
                      </Col>
                    ) : null}

                    <Col
                      md={12}
                      className="d-flex justify-content-start flex-wrap gap-3 mt-3 pt-2"
                    >
                      <span className="quantity-top-margin">Quantity:</span>
                      <InputNumberField
                        name="quantity"
                        placeHolder="0"
                        className="add-inventory-quantity-field"
                        isConvertToDecimal={false}
                      />
                      <CategoriesDropdown
                        options={editProductObject?.productConditionList || []}
                        handleChange={(selectedOption) =>
                          handleEditBatchConditionChange(
                            markupPresets,
                            selectedOption,
                            setProductCondition,
                            productTags,
                            setProductTagsList,
                            setProductDataObject,
                            productDataObject,
                            setSelectedTags,
                            tradeinMargin,
                            setAddSelectedTags,
                            true
                          )
                        }
                        noOptionsMessage="No Condition Found"
                        placeholder="Condition"
                        SelectIconImage={
                          categoryDropdownConstants.INVENTORY_LIST_ICON
                        }
                        isClearable={false}
                        className="add-inventory-dropdowns-category condition-dropdown"
                        value={productCondition}
                      />
                      {productDataObject?.genre ===
                        TRADING_CARD_CATEGORIES_ENUMS.POKEMON_CARD ||
                      productDataObject?.genre ===
                        TRADING_CARD_CATEGORIES_ENUMS.MAGIC_CARD ? (
                        <CategoriesDropdown
                          options={rarities || []}
                          handleChange={(selectedOption) =>
                            handleRaritiesChange(
                              selectedOption,
                              setSelectedRarity,
                              setProductDataObject,
                              productDataObject,
                              tradeinMargin,
                              markupPresets,
                              true
                            )
                          }
                          noOptionsMessage="No Printing Found"
                          placeholder="Printings"
                          SelectIconImage={
                            categoryDropdownConstants.INVENTORY_LIST_ICON
                          }
                          isClearable={false}
                          className="add-inventory-dropdowns-category condition-dropdown"
                          value={selectedRarity}
                        />
                      ) : (
                        ""
                      )}
                    </Col>
                    {productCondition?.value ===
                      PRODUCT_CONDITIONS_ENUMS.COMPLETE ||
                    productCondition?.value ===
                      PRODUCT_CONDITIONS_ENUMS.LOOSE ? (
                      <Col
                        md={12}
                        className="d-flex align-items-center gap-3 my-2"
                      >
                        {productDataObject.genre ===
                        TRADING_CARD_CATEGORIES_ENUMS.SYSTEM ? (
                          productCondition?.value ===
                          PRODUCT_CONDITIONS_ENUMS.COMPLETE ? (
                            <>
                              <CheckboxField name="manual" label="Manual" />
                              {/* <CheckboxField name="box" label="Box" /> */}
                              <CheckboxField name="cables" label="Cables" />
                              <CheckboxField
                                name="controller"
                                label="Controller"
                              />
                            </>
                          ) : productCondition?.value ===
                            PRODUCT_CONDITIONS_ENUMS.LOOSE ? (
                            <>
                              <CheckboxField name="manual" label="Manual" />
                              <CheckboxField name="cables" label="Cables" />
                              <CheckboxField
                                name="controller"
                                label="Controller"
                              />
                            </>
                          ) : (
                            ""
                          )
                        ) : [
                            ...inventoryConstants.NINTENDO_CATEGORIES,
                            ...inventoryConstants.SKYLANDER_CATEGORIES,
                          ].includes(editProductObject?.consoleName) ? (
                          ""
                        ) : (
                          <CheckboxField name="manual" label="Manual" />
                        )}
                      </Col>
                    ) : (
                      ""
                    )}

                    <Col
                      md={12}
                      className="my-2 d-flex align-items-center gap-4"
                    >
                      <ProductTagList
                        tagsList={productTagsList || []}
                        suggestedArrayList={productTags}
                        onSelectTagsChange={onSelectTagsChange}
                        productDetail={productDataObject}
                        selectedManual={{
                          isChecked: values.manual,
                          value:
                            productCondition?.value ===
                            PRODUCT_CONDITIONS_ENUMS.COMPLETE
                              ? inventoryConstants.PRODUCT_TAGS.MISSING_MANUAL
                              : productCondition?.value ===
                                PRODUCT_CONDITIONS_ENUMS.LOOSE
                              ? inventoryConstants.PRODUCT_TAGS.WITH_MANUAL
                              : "",
                        }}
                        selectedWires={{
                          isChecked: values.cables,
                          value:
                            PRODUCT_CONDITIONS_ENUMS.COMPLETE ||
                            productCondition?.value ===
                              PRODUCT_CONDITIONS_ENUMS.LOOSE
                              ? inventoryConstants.PRODUCT_TAGS.MISSING_WIRES
                              : "",
                        }}
                        selectedController={{
                          isChecked: values.controller,
                          value:
                            PRODUCT_CONDITIONS_ENUMS.COMPLETE ||
                            productCondition?.value ===
                              PRODUCT_CONDITIONS_ENUMS.LOOSE
                              ? inventoryConstants.PRODUCT_TAGS
                                  .MISSING_CONTROLLER
                              : "",
                        }}
                      />
                    </Col>
                  </Row>
                </Col>

                <Col xs={12} className="m-0 p-0 mt-4">
                  <Row className="m-0">
                    <Col
                      md={4}
                      className="add-inventory-prices-detail-wrapper d-flex flex-column align-items-lg-stretch gap-3"
                    >
                      <div>
                        <span>GameStop Buy Price</span>
                        <span>{`$${productDataObject.gameStopBuyPrice}`}</span>
                      </div>
                      <div>
                        <span>Average Buy Price</span>
                        <span>{`$${
                          productDataObject.averageBuyPrice || 0
                        }`}</span>
                      </div>
                      <div>
                        <span>Max Buy Price</span>
                        <span>{`$${productDataObject.maxBuyPrice || 0}`}</span>
                      </div>
                      <div>
                        <span>Average Sell Price</span>
                        <span>{`$${
                          productDataObject.averageSellPrice || 0
                        }`}</span>
                      </div>
                    </Col>
                    <Col md={8}>
                      <Row className="m-0  h-100">
                        <Col xs={12} className="d-flex flex-column gap-1">
                          <div className="w-100 d-flex justify-content-between add-inventory-price-heading">
                            <span>Market Price: </span>
                            <span>
                              {`$${parseToThousandSeperatorDecimalNumber(
                                productDataObject.marketPrice
                              )}`}
                            </span>
                          </div>
                          {isTradiingCardWarningText && (
                            <div className="w-100 d-flex justify-content-end trading-card-warning-text">
                              <span>Price is only for Near-Mint condition</span>
                            </div>
                          )}
                          <div className="w-100 d-flex justify-content-between add-inventory-price-sub-heading">
                            <span>Suggested sell price: </span>
                            <span>
                              {`$${parseToThousandSeperatorDecimalNumber(
                                productDataObject.suggestedSellPrice
                              )}`}
                            </span>
                          </div>
                          <div className="w-100 d-flex justify-content-between add-inventory-price-sub-heading">
                            <span>In-Stock Price: </span>
                            <span>
                              {`$${productDataObject.inStorePrice || 0}`}{" "}
                            </span>
                          </div>
                        </Col>
                        <Col
                          xs={12}
                          className="d-flex flex-column gap-3 m-0 my-3"
                        >
                          <div className="add-inventory-stock-price-wrapper">
                            <div className="w-100 d-flex justify-content-between add-inventory-price-sub-heading">
                              <span className="addTrade-gray-text">
                                Margin{" "}
                              </span>
                            </div>
                            <div className="mt-3 mb-3 col-md-5">
                              <CategoriesDropdown
                                options={[
                                  inventoryConstants.MARGIN_TYPE_GLOBAL,
                                  inventoryConstants.MARGIN_TYPE_STANDARD,
                                ]}
                                disabled={
                                  getMarginDropdownValue(
                                    productDataObject?.marketPrice,
                                    tradeinMargin,
                                    productType,
                                    productDataObject
                                  )?.marginType ===
                                  inventoryConstants.MARGIN_TYPE_GLOBAL
                                }
                                handleChange={(selectedOption) =>
                                  handleMarginSelectChange(
                                    selectedOption,
                                    setFieldValue
                                  )
                                }
                                noOptionsMessage="No Condition Found"
                                placeholder="Global"
                                SelectIconImage={
                                  categoryDropdownConstants.INVENTORY_LIST_ICON
                                }
                                isClearable={false}
                                defaultValue={
                                  productDataObject?.tradeInMarginTypeObject
                                    ?.marginType
                                }
                                className="add-inventory-dropdowns-category condition-dropdown remove-minWidth-dropdown"
                                // value={marginTypeObject?.marginType}
                              />
                            </div>
                            <div className="w-100 mb-3 d-flex justify-content-between align-items-center add-inventory-price-heading">
                              <span className="addTrade-gray-text">
                                Cash Offer:{" "}
                              </span>
                              <InputNumberField
                                name="cashOffer"
                                placeHolder="0"
                                className="add-inventory-price-field input-background"
                                IconImage={DollarIcon}
                                handleChange={(value) => {
                                  handleCashOfferValues(value, setFieldValue);
                                }}
                              />
                            </div>
                            <div className="w-100 d-flex justify-content-between align-items-center add-inventory-price-heading">
                              <span className="addTrade-gray-text">
                                Trade Offer:{" "}
                              </span>
                              <InputNumberField
                                name="tradeOffer"
                                placeHolder="0"
                                className="add-inventory-price-field input-background "
                                IconImage={DollarIcon}
                                handleChange={(value) => {
                                  handleTradeOfferValues(value, setFieldValue);
                                }}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col xs={12} className="d-flex flex-column gap-3 mb-3">
                          <div className="add-inventory-stock-price-wrapper">
                            <div className="w-100 d-flex justify-content-between add-inventory-price-sub-heading">
                              <span>Stock Price </span>
                            </div>
                            <div className="w-100 d-flex justify-content-between align-items-center add-inventory-price-heading">
                              <span>In-Store: </span>
                              <InputNumberField
                                name="inStockPrice"
                                placeHolder="0"
                                className="add-inventory-price-field"
                                IconImage={DollarIcon}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col md={12} className="d-flex align-items-end gap-2">
                          <Button
                            label={buttonNameConstants.CANCEL}
                            className="w-100"
                            type="button"
                            handleClick={() => handleHideModal()}
                            buttonType={buttonTypeConstants.GHOST_BUTTON}
                          />
                          <Button
                            label={buttonNameConstants.SAVE}
                            className="w-100"
                            type="submit"
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {productUrl && (
                <Row className="m-0 w-50">
                  <Col md={12}>
                    {productUrl && (
                      <iframe
                        title="Product Iframe"
                        src={productUrl}
                        className="iframe-website"
                      />
                    )}
                  </Col>
                </Row>
              )}
            </Modal.Body>
          </Form>
        )}
      </Formik>
      {productUrl && (
        <button
          type="button"
          onClick={() => {
            setProductUrl("");
          }}
          className="iframe-toogle-button"
        >
          <SidebarToggleIcon className={"iframe-toggle-icon"} />
        </button>
      )}
    </Modal>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  inventory: state.inventory,
  tradeDiscount: state.tradeDiscount,
  currentStore: state.store.currentStore,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  activateSpinner: (data) => dispatch(systemActions.activateSpinner(data)),
  deactivateSpinner: (data) => dispatch(systemActions.deactivateSpinner(data)),
});

//-------Export AddNewUser Component
export default connect(mapStateToProps, mapDispatchToProps)(BatchTradeEdit);
