import moment from "moment";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { Row, Col } from "react-bootstrap";
import { useDebouncedCallback } from "use-debounce";
import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";

import FilterTags from "./PriceChangesFilter/FilterTags";
import { inventoryActions } from "../../../redux/actions";
import EditPriceModal from "./ChangePrice/EditPriceModal";
import Topbar from "../../../shared/components/topbar/Topbar";
import Button from "../../../shared/components/Buttons/Button";
import PriceChangesFilter from "./PriceChangesFilter/PriceChangesFilter";
import SearchField from "../../../shared/components/searchField/SearchField";
import TableCustomHeader from "../../../shared/components/table/TableCustomHeader";
import ServerPaginationTable from "../../../shared/components/agGrid/ServerPaginationTable";
import DropdownSkeleton from "../../../shared/Skeletons/DropdownSkeleton/DropdownSkeleton";
import FilterDropdowns from "../../../shared/components/FilterDropdowns/FilterDropdowns";
import {
  globalConstants,
  inventoryConstants,
  buttonNameConstants,
  defaultGridState,
  ROLES,
} from "../../../constants";
import {
  CrossIcon,
  SearchIcon,
  BlueBoxIcon,
  EditIcon,
  TickIcon,
} from "../../../assets/icons/iconsProvider";
import {
  handleKeyDown,
  getShortestSku,
  LoadingIndicator,
  getDefaultFilterType,
  parseToThousandSeperatorDecimalNumber,
  parseToThousandSeperatorWholeNumber,
  isSpinnerEnabled,
  isButtonDisable,
  prepareFilterForPaginationCall,
  getProductDetailCardTags,
} from "../../../shared/utility";
import {
  PRODUCT_TYPES_ENUMS,
  PRICE_CHANGE_FILTER_ENUMS,
} from "../../../system/globalEnums";
import CustomGridHeader from "../../../shared/components/agGrid/CustomGridHeader";
import AGGridToolTip from "../../../shared/components/toolTipComponent/AGGridToolTip";

const PriceChanges = (props) => {
  //-------Destructure Props
  const {
    inventory,
    role,
    currentStore,
    thresholdData,
    itemOrganization,
    spinnerArray,
    updateInventory,
    getPriceChangeThreshold,
    addPriceChangeThreshold,
    emptyInventoryProductsList,
    getInventoryByPricechartingId,
    getPaginatedInventory,
  } = props;

  const navigate = useNavigate();

  //-------Inventory Reducer
  const { allInventory } = inventory;
  //-------Destructure Reducer
  const { customTypes, customCategories, customSubCategories } =
    itemOrganization;

  //-------UseRef
  const gridRef = useRef();
  const rowBeingEditRef = useRef({});
  const rowBeingEditParamRef = useRef({});
  const rowToMoveRef = useRef({});

  //-------UseStates
  const [columns, setColumns] = useState([]);
  const [searchText, setSearchText] = useState();
  const [gridState, setGridState] = useState(defaultGridState);
  const [isFilterModal, setIsFilterModal] = useState(false);
  const [isEditPriceModal, setIsEditPriceModal] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [debouncedSearchText, setDebouncedSearchText] = useState("");
  const [searchProductType, setSearchProductType] = useState(
    getDefaultFilterType(customTypes, PRODUCT_TYPES_ENUMS.ALL)
  );
  const [searchProductCategory, setSearchProductCategory] = useState("");
  const [searchProductSubCategory, setSearchProductSubCategory] = useState("");

  const toggleFilterModal = () => {
    setIsFilterModal(!isFilterModal);
  };

  const toggleEditPriceModal = () => {
    emptyInventoryProductsList([]);
    setIsEditPriceModal(!isEditPriceModal);
  };

  const components = useMemo(() => {
    return {
      agColumnHeader: CustomGridHeader,
    };
  }, []);

  const getRowStyle = (params) => {
    return null;
  };

  const handleRefreshCells = (params) => {
    params.api.refreshCells({
      columns: [
        "id",
        "price.unit_sell_price",
        "price.unit_purchase_price",
        "price.quantity",
      ],
      rowNodes: [params.node],
      force: true,
    });
  };

  const redrawTopRows = useCallback((params) => {
    params.api.redrawRows({ rowNodes: [params.node] });
  }, []);

  //-------handle Edit Submit Click
  const handleEditSubmitClick = (params) => {
    const {
      id,
      margin,
      price,
      originalQuantity,
      imgUrl,
      store,
      originalPrice,
      upc,
      product_name,
      productType,
      category_name,
      subcategory,
      cardRarity,
    } = rowBeingEditRef.current;
    if (id) {
      const updateInventoryObject = {
        id: id,
        margin: margin || 0,
        imgUrl: imgUrl,
        upc: upc,
        product_name: product_name,
        productType: productType,
        category_name: category_name,
        subcategory: subcategory,
        cardRarity: cardRarity,
        price: {
          type: price.type,
          quantity: price.quantity,
          unit_purchase_price: price.unit_purchase_price,
          unit_sell_price: price.unit_sell_price,
          marketPrice: price.marketPrice || 0,
          profit: price.profit || 0,
        },
      };
      updateInventory(store?.id, updateInventoryObject, true, gridState);
      rowBeingEditRef.current = {};
    }
  };

  const handleCancelEdit = (params) => {
    rowBeingEditRef.current = {};
    redrawTopRows(params);
    rowBeingEditParamRef.current = {};
    handleRefreshCells(params);
  };

  function actionCellRenderer(params) {
    if (params.data?.id) {
      if (rowBeingEditRef.current.id === params.data.id) {
        return (
          <>
            <button
              id={`modalTool${params.data.id}`}
              className="action-Button-Wrapper"
              onClick={() => handleEditSubmitClick(params)}
            >
              <TickIcon className="cursor-pointer text-primary" />
            </button>
            <button
              id={`modalTool${params.data.id}`}
              className="action-Button-Wrapper"
              onClick={() => handleCancelEdit(params)}
            >
              <CrossIcon className="cursor-pointer" />
            </button>
          </>
        );
      } else {
        return (
          <div>
            <button
              className="action-Button-Wrapper ps-0 pe-0"
              onClick={() => {
                handleEditClick(params.data, params.rowIndex);
              }}
            >
              <EditIcon className="cursor-pointer text-primary" />
            </button>
          </div>
        );
      }
    } else {
      return <DropdownSkeleton width="5em" height="1.5em" />;
    }
  }

  const updateGridState = (gridState) => {
    getPaginatedInventory(currentStore?.id, gridState, true);
    setGridState(gridState);
  };

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: { display: "flex", alignItems: "center" },
      resizable: false,
      tooltipComponent: AGGridToolTip,
      headerTooltipComponent: AGGridToolTip,
      headerComponentParams: {
        gridState: gridState,
        updateGridState: updateGridState,
      },
    };
  }, [gridState]);

  // Column Definitions: Defines the columns to be displayed.
  const colDefs = useMemo(
    () => [
      {
        headerName: "Name",
        field: "product_name",
        cellRenderer: (row) =>
          row?.data?.product_name ? (
            <span
              className="inventory-cell-max-size product-edit-anchor-link cursor-pointer"
              onClick={() => handleEditClick(row.data)}
            >
              <TableCustomHeader
                id={`product-name-in-array-${getShortestSku(row.data.sku)}`}
                label={
                  <span className="inventory-cell-max-size">
                    {row.data.product_name}
                  </span>
                }
                tooltopText={
                  <p className="text-start fw-normal m-0">
                    {row.data.product_name}
                  </p>
                }
                className="sku-tooltip"
              />
            </span>
          ) : (
            <DropdownSkeleton width="5em" height="1.5em" />
          ),

        cellStyle: {
          overflow: "visible",
        },
      },
      {
        headerName: "Condition",
        field: "price.type",
        cellRenderer: (params) =>
          params?.data?.price?.type ? (
            <span className="inventory-cell-max-size">
              {params?.data?.price?.type}
            </span>
          ) : (
            <DropdownSkeleton width="5em" height="1.5em" />
          ),
      },
      {
        headerName: "Type",
        field: "productType",
        cellRenderer: (row) => {
          return row?.data?.productType ? (
            row.data.productType
          ) : (
            <DropdownSkeleton width="5em" height="1.5em" />
          );
        },
      },
      {
        headerName: "Category",
        field: "category_name",
        cellRenderer: (params) =>
          params?.data?.category_name ? (
            <span className="inventory-cell-max-size">
              {params.data.category_name}
            </span>
          ) : (
            <DropdownSkeleton width="5em" height="1.5em" />
          ),
      },
      {
        headerName: "Tags",
        field: "tags",
        cellStyle: {
          overflow: "visible",
        },
        cellRenderer: (params) =>
          params?.data?.tags ? (
            <span className="inventory-cell-max-size">
              {getProductDetailCardTags(
                params?.data?.tags?.filter(
                  (tag) => tag !== inventoryConstants.CUSTOM_ITEM_TAG
                ),
                params?.data?.sku
              )}
            </span>
          ) : (
            <DropdownSkeleton width="5em" height="1.5em" />
          ),
      },
      {
        headerTooltip: "Unit Sell Price",
        headerName: "Unit Sel...",
        field: "price.unit_sell_price",
        cellRenderer: (params) => {
          return params.data?.price ? (
            params.data.id === rowBeingEditRef?.current?.id ? (
              <input
                min={0}
                type="number"
                defaultValue={params.data.price.unit_sell_price}
                className="input-textfield input-textfield-without-icon  editable-table-row-fields inventory-editable-row-fields"
                onChange={(e) => {
                  rowBeingEditRef.current = {
                    ...rowBeingEditRef.current,
                    price: {
                      ...rowBeingEditRef.current.price,
                      unit_sell_price: e.target.value,
                    },
                  };
                }}
              ></input>
            ) : (
              <span className="inventory-cell-max-size">
                $
                {parseToThousandSeperatorDecimalNumber(
                  params.data.price.unit_sell_price
                )}
              </span>
            )
          ) : (
            <DropdownSkeleton width="5em" height="1.5em" />
          );
        },
      },
      {
        headerTooltip: "Current Market Price",
        headerName: "Current M...",
        field: "price.marketPrice",
        cellRenderer: (params) => {
          return params.data?.price ? (
            <span className="inventory-cell-max-size">
              $
              {parseToThousandSeperatorDecimalNumber(
                params.data.price.marketPrice
              )}
            </span>
          ) : (
            <DropdownSkeleton width="5em" height="1.5em" />
          );
        },
      },
      {
        headerTooltip: "Percentage Difference",
        headerName: "Percentage...",
        field: "price.percentageDifference",
        cellRenderer: (params) => {
          return params.data?.price ? (
            <span
              className={`inventory-cell-max-size 
              ${
                params.data.price.percentageDifference < 0
                  ? " text-red"
                  : "text-purple"
              }
              `}
            >
              {parseToThousandSeperatorDecimalNumber(
                params.data.price.percentageDifference
              )}
              %
            </span>
          ) : (
            <DropdownSkeleton width="5em" height="1.5em" />
          );
        },
      },
      {
        headerTooltip: "Cash Difference",
        headerName: "Cash Di...",
        field: "price.cashDifference",
        cellRenderer: (params) => {
          return params.data?.price ? (
            <span
              className={`inventory-cell-max-size 
              ${
                params.data.price.cashDifference < 0
                  ? " text-red"
                  : "text-purple"
              }
              `}
            >
              $
              {parseToThousandSeperatorDecimalNumber(
                params.data.price.cashDifference
              )}
            </span>
          ) : (
            <DropdownSkeleton width="5em" height="1.5em" />
          );
        },
      },
      {
        maxWidth: 150,
        headerName: "",
        field: "id",
        cellStyle: {
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          overflow: "visible",
        },
        lockPosition: "right",
        cellRenderer: actionCellRenderer,
        sortable: false,
      },
    ],
    []
  );

  const handleSearchInputChange = (value) => {
    setIsSearchLoading(true);
    setDebouncedSearchText(value);
    handleDebouncedSearch(value);
  };

  const handleDebouncedSearch = useDebouncedCallback((value) => {
    updateGridState({ ...gridState, searchQuery: value, pageIndex: 1 });
  }, 1000);

  const clearSearchText = () => {
    handleSearchInputChange("");
  };

  //-------handle edit product click
  const handleEditClick = (row, index) => {
    rowToMoveRef.current = {
      row: index ? gridData[index - 1] : {},
      index: index,
    };
    getInventoryByPricechartingId(currentStore?.id, {
      id: row?.product_id,
      productName: row?.product_name,
      categoryName: row?.category_name,
    });
    toggleEditPriceModal();
  };

  const sortByIsDismiss = (a, b) => {
    return a.price.isDismiss - b.price.isDismiss;
  };

  const getRowId = useCallback((params) => params.data.id, []);

  const rowClassRules = useMemo(() => {
    return {
      // row style function
      "highlighted-row": (params) => {
        return (
          params?.data?.id && params?.data?.id === rowBeingEditRef.current?.id
        );
      },
    };
  }, []);

  const onCellClicked = useCallback((params) => {
    if (
      params.column.colId === "id" ||
      params.column.colId === "product_name" ||
      params.data.id === rowBeingEditRef?.current?.id
    ) {
    } else {
      if (rowBeingEditParamRef.current?.data?.id) {
        handleRefreshCells(rowBeingEditParamRef.current);
        redrawTopRows(rowBeingEditParamRef.current);
      }
      rowBeingEditParamRef.current = params;
      rowBeingEditRef.current = {
        ...params.data,
        originalQuantity: params.data.price.quantity,
        originalPrice: params.data.price.unit_sell_price,
      };
      handleRefreshCells(params);

      redrawTopRows(params);
    }
  }, []);

  useEffect(() => {
    if (currentStore?.id) {
      getPriceChangeThreshold(currentStore?.id);
    }
  }, [currentStore?.id]);

  useEffect(() => {
    if (currentStore?.id) {
      updateGridState({
        ...gridState,
        pageIndex: 1,
        filters: prepareFilterForPaginationCall(
          searchProductType,
          searchProductCategory,
          searchProductSubCategory
        ),
      });
    }
  }, [
    currentStore,
    searchProductType,
    searchProductCategory,
    searchProductSubCategory,
  ]);

  const scrollToRow = () => {
    if (gridData?.find((item) => rowToMoveRef?.current?.row?.id === item.id)) {
      var tableElement =
        document.getElementsByClassName("ag-body")[
          rowToMoveRef?.current?.index
        ];
      if (tableElement) {
        tableElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  };

  const gridData = useMemo(() => {
    let filteredData = allInventory?.inventory || [];
    setGridState({
      ...gridState,
      pageIndex: allInventory?.pageNumber || defaultGridState?.pageIndex,
      totalRecords: allInventory.totalRecords || defaultGridState.totalRecords,
      pageEndRecordNumber:
        allInventory.pageEndRecordNumber ||
        defaultGridState.pageEndRecordNumber,
      pageStartRecordNumber:
        allInventory.pageStartRecordNumber ||
        defaultGridState.pageStartRecordNumber,
    });
    setIsSearchLoading(false);

    return filteredData;
  }, [allInventory]);

  useEffect(() => {
    setSearchProductType(
      getDefaultFilterType(customTypes, PRODUCT_TYPES_ENUMS.ALL)
    );
  }, [customTypes]);

  useEffect(() => {
    scrollToRow();
  }, [gridData]);

  const skeletonArray = Array(10).fill({});

  return (
    <>
      <Topbar title="Price Changes">
        <div className="price-change-filter-tag">
          Minimum Difference:{" "}
          {thresholdData?.global?.valueType ?? true ? "$" : "%"}
          {parseToThousandSeperatorDecimalNumber(
            thresholdData?.global?.value ?? 0
          )}
        </div>
        <Button
          label={buttonNameConstants.MANAGE}
          handleClick={toggleFilterModal}
        />
      </Topbar>
      <Row className="m-0 h-100">
        <Col md={6}>
          <div>
            {isSpinnerEnabled(
              spinnerArray,
              inventoryConstants.GET_ALL_INVENTORY
            ) ? (
              <DropdownSkeleton width="100%" />
            ) : (
              <SearchField
                isFocus={true}
                onChange={(e) => handleSearchInputChange(e.target.value)}
                IconImage={SearchIcon}
                label="Search"
                placeHolder="Type or Scan"
                value={debouncedSearchText}
                CrossIcon={debouncedSearchText ? CrossIcon : ""}
                clearSearchText={clearSearchText}
                BlueBoxIcon={BlueBoxIcon}
                LoadingIndicator={
                  isSearchLoading ||
                  (isButtonDisable(
                    spinnerArray,
                    inventoryConstants.GET_ALL_INVENTORY
                  ) &&
                    searchText)
                    ? LoadingIndicator
                    : false
                }
              />
            )}
          </div>
        </Col>
        <Col md={6} className="ps-0">
          {isSpinnerEnabled(
            spinnerArray,
            inventoryConstants.GET_ALL_INVENTORY
          ) && (
            <div className="w-100 d-flex align-item-center gap-3">
              <DropdownSkeleton />
              <DropdownSkeleton />
              <DropdownSkeleton />
            </div>
          )}
          <div
            className={`w-100 ${
              isSpinnerEnabled(
                spinnerArray,
                inventoryConstants.GET_ALL_INVENTORY
              )
                ? "opacity-0 hide-filter-dropdowns "
                : "opacity-100"
            }  `}
          >
            <FilterDropdowns
              customTypes={customTypes}
              customCategories={customCategories}
              customSubCategories={customSubCategories}
              setSearchProductType={setSearchProductType}
              setSearchProductCategory={setSearchProductCategory}
              setSearchProductSubCategory={setSearchProductSubCategory}
              defaultProductType={PRODUCT_TYPES_ENUMS.ALL}
              isDisabledAll={isButtonDisable(
                spinnerArray,
                inventoryConstants.GET_ALL_INVENTORY
              )}
            />
          </div>
        </Col>
        <Col md={12} className="mb-3">
          <FilterTags thresholdData={thresholdData} />
        </Col>
        <Col md={12} className="px-0 h-100">
          <Row className="m-0 w-100 h-100 user-screen-wrapper">
            <ServerPaginationTable
              colDefs={colDefs}
              gridRef={gridRef}
              gridData={
                isSpinnerEnabled(spinnerArray, [
                  inventoryConstants.GET_ALL_INVENTORY,
                  inventoryConstants.UPDATE_INVENTORY,
                ])
                  ? skeletonArray
                  : gridData
              }
              getRowId={getRowId}
              gridState={gridState}
              components={components}
              getRowStyle={getRowStyle}
              onCellClicked={onCellClicked}
              rowClassRules={rowClassRules}
              defaultColDef={defaultColDef}
              updateGridState={updateGridState}
              paginationPageSize={gridState.pageSize}
              pageSizeOptions={gridState.pageSizeOptions}
              isDisablePaginationActions={isButtonDisable(
                spinnerArray,
                inventoryConstants.GET_ALL_INVENTORY
              )}
              tableHeight={`calc(100vh - 230px)`}
              isDataLoading={isSpinnerEnabled(spinnerArray, [
                inventoryConstants.GET_ALL_INVENTORY,
                inventoryConstants.UPDATE_INVENTORY,
              ])}
            />
          </Row>
        </Col>
      </Row>
      {isFilterModal && (
        <PriceChangesFilter
          customTypes={customTypes}
          currentStore={currentStore}
          spinnerArray={spinnerArray}
          isFilterModal={isFilterModal}
          thresholdData={thresholdData}
          customCategories={customCategories}
          toggleFilterModal={toggleFilterModal}
          addPriceChangeThreshold={addPriceChangeThreshold}
        />
      )}
      {isEditPriceModal && (
        <EditPriceModal
          mainTableGridState={gridState}
          currentStore={currentStore}
          isEditPriceModal={isEditPriceModal}
          toggleEditPriceModal={toggleEditPriceModal}
        />
      )}
    </>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  inventory: state.inventory,
  role: state.authentication.user.role,
  currentStore: state.store.currentStore,
  itemOrganization: state.itemOrganization,
  spinnerArray: state.system.spinnerArray,
  thresholdData: state.inventory.thresholdData,
  priceChangesData: state.inventory.priceChangesData,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getPriceChangeThreshold: (storeId) =>
    dispatch(inventoryActions.getPriceChangeThreshold(storeId)),
  addPriceChangeThreshold: (data, handleHideModal) =>
    dispatch(inventoryActions.addPriceChangeThreshold(data, handleHideModal)),
  getPriceChanges: (storeId) =>
    dispatch(inventoryActions.getPriceChanges(storeId)),
  getInventoryByPricechartingId: (storeId, data) =>
    dispatch(inventoryActions.getInventoryByPricechartingId(storeId, data)),
  emptyInventoryProductsList: () =>
    dispatch(inventoryActions.emptyInventoryProductsList()),
  getPaginatedInventory: (storeId, gridState, isPriceChangesApiCall) =>
    dispatch(
      inventoryActions.getPaginatedInventory(
        storeId,
        gridState,
        isPriceChangesApiCall
      )
    ),
  updateInventory: (storeId, data, isLabelNotification, gridState) =>
    dispatch(
      inventoryActions.updatePriceChangeProduct(
        storeId,
        data,
        isLabelNotification,
        gridState
      )
    ),
});

//-------Export AddNewUser Component
export default connect(mapStateToProps, mapDispatchToProps)(PriceChanges);
