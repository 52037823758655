import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { Row, Col, Modal, Form } from "react-bootstrap";

import { transactionActions } from "../../../redux/actions";
import { PRODUCT_TYPES_ENUMS } from "../../../system/globalEnums";
import Topbar from "../../../../src/shared/components/topbar/Topbar";
import CustomerImage from "../../../assets/images/customerImage.svg";
import Button from "../../../../src/shared/components/Buttons/Button";
import SendReceiptModal from "./ImportTransactionHistory/SendReceiptModal";
import TableCustomHeader from "../../../shared/components/table/TableCustomHeader";
import SearchField from "../../../../src/shared/components/searchField/SearchField";
import SelectDropDown from "../../../shared/components/selectDropdown/SelectDropdown";
import PrintLabelModal from "../../Inventory/Items/printingLabelModal/PrintLabelModal";
import { printReceiptData } from "../../../shared/Printers/ReceiptPrinters/printReceipt";
import ImportTransactionHistory from "./ImportTransactionHistory/ImportTransactionHistory";
import DropdownSkeleton from "../../../shared/Skeletons/DropdownSkeleton/DropdownSkeleton";
import CustomTooltip from "../../../../src/shared/components/toolTipComponent/CustomTooltip";
import CategoriesDropdown from "../../../shared/components/selectDropdown/CategoriesDropdown";
import DataTableComponent from "../../../../src/shared/components/table/ReactDataTableComponent";
import { viewCartPrintDropdownStyle } from "../../../assets/scss/style/viewCartPrintDropdownStyle";
import SummaryCardSkeleton from "../../../shared/Skeletons/inventorySkeletons/SummaryCardSkeleton";
import { prepareReceiptForPrinter } from "../../../shared/Printers/ReceiptPrinters/prepareReceiptForPrinter";
import DatetimePickerDropdown from "../../../shared/components/datetimePickerDropdown/DatetimePickerDropdown";
import TransactionOverViewCard from "../../../shared/components/transactionOverViewCard/TransactionOverViewCard";
import { prepareReceiptForPrinterTrade } from "../../../shared/Printers/ReceiptPrinters/prepareReceiptForPrinterTrade";
import {
  globalConstants,
  inventoryConstants,
  buttonNameConstants,
  buttonTypeConstants,
  transactionConstants,
  categoryDropdownConstants,
  EMPTY_STRING,
  toastMessages,
  toastType,
  routesPathConstants,
  defaultGridState,
} from "../../../constants";
import {
  ShowIcon,
  CrossIcon,
  SearchIcon,
  DeleteIcon,
  BlueBoxIcon,
  ExportCSVIcon,
} from "../../../assets/icons/iconsProvider";
import {
  formatDate,
  downloadCSV,
  parseToNumber,
  getShortestSku,
  calcullateItems,
  isSpinnerEnabled,
  getReceiptPrintDate,
  getUnitPurchasePrice,
  parseToDecimalNumber,
  getProductDetailCardTags,
  parseToThousandSeperatorDecimalNumber,
  parseToThousandSeperatorWholeNumber,
  stringifyObject,
  customToast,
  parseJsonObject,
  isTradeTransaction,
  isSaleTransaction,
  isReturnTransaction,
  LoadingIndicator,
  isButtonDisable,
} from "../../../shared/utility";
import CustomerDropdown from "../../../shared/components/selectDropdown/CustomerDropdown";
import { useNavigate } from "react-router";
import AddNewCustomerModal from "../NewTransaction/AddNewCustomer/AddNewCustomerModal";
import ServerPaginationTable from "../../../shared/components/agGrid/ServerPaginationTable";
import CustomGridHeader from "../../../shared/components/agGrid/CustomGridHeader";
import AGGridToolTip from "../../../shared/components/toolTipComponent/AGGridToolTip";
import { useDebouncedCallback } from "use-debounce";

//-------History component start here
const History = (props) => {
  const {
    user,
    printer,
    customer,
    transaction,
    spinnerArray,
    currentStore,
    sendEmailOrSMS,
    getAllTransaction,
    emptyDuplicateRecords,
    bulkUploadTransactionHistory,
    isAddCustomerModal,
    toggleAddCustomerModal,
    updateCustomerInTransactionHistory,
  } = props;

  //-------UseRef
  const gridRef = useRef();

  const [customersList, setCustomersList] = useState([]);
  const [gridState, setGridState] = useState(defaultGridState);
  const { defaultReceiptPrinter } = printer;
  const [debouncedSearchText, setDebouncedSearchText] = useState(EMPTY_STRING);
  const [isSearchLoading, setIsSearchLoading] = useState(false);

  const navigate = useNavigate();

  //-------History reducer
  const { allTransaction, duplicateHistoryRecords } = transaction;
  const { sellTradeStats } = allTransaction;

  //-------Customer reducer
  const { allCustomers } = customer; //------printer reducer
  const { sellTradeHistory, paginationData } = allTransaction;

  const [show, setShow] = useState(false);
  const [idType, setIdType] = useState("");
  const [searchText, setSearchText] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [viewCartDetail, setViewCartDetail] = useState([]);
  const [isAddCustomer, setIsAddCustomer] = useState(false);

  const [isReceiptModal, setIsReceiptModal] = useState(false);
  const [printRecieptData, setprintRecieptData] = useState([]);
  const [printLabelModal, setPrintLabelModal] = useState(false);
  const [addCustomerModal, setAddCustomerModal] = useState(false);
  const [isTypeSearchLoading, setIsTypeSearchLoading] = useState(false);
  const [selectedCustomerFlag, setSelectedCustomerFlag] = useState(false);
  const [importTransactionHistoryModal, setImportTransactionHistoryModal] =
    useState(false);
  const [option, setOption] = useState({
    label: globalConstants.ALL,
    value: globalConstants.ALL,
  });
  const [timeFrame, setTimeFrame] = useState({
    start: "",
    end: "",
    duration: "daily",
  });
  //-------Handle History edit click
  const handleViewCart = (row) => {
    const idPrefix = row.id.substring(0, 3);
    setIdType(idPrefix);
    setprintRecieptData(row);
    setViewCartDetail([row]);
    setSelectedRows([]);
    setSelectedRows(
      row?.inventory.map((item) => {
        const newObject = {
          sku: getShortestSku(item.sku),
          product_name: item.product_name,
          price: {
            quantity: item?.price?.quantity,
            type: item?.price?.type,
            unit_sell_price: item?.price?.unit_purchase_price,
          },
          category_name: item?.category_name || item?.category,
        };
        return newObject;
      })
    );
    toggleCustomTradeModal();
  };

  //-------handle add customer click
  const handleAddNewCustomerClick = () => {
    toggleAddCustomerModal(true);
  };

  const toggleImportTransactionHistoryModal = () => {
    setImportTransactionHistoryModal(!importTransactionHistoryModal);
  };

  const updateGridState = (gridState) => {
    // getPaginatedInventoryHistory(currentStore?.id, gridState);
    getAllTransaction(
      currentStore?.id,
      option?.value,
      timeFrame.start,
      timeFrame.end,
      gridState
    );
    setGridState(gridState);
  };

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: { display: "flex", alignItems: "center" },
      resizable: false,
      tooltipComponent: AGGridToolTip,
      headerComponentParams: {
        gridState: gridState,
        updateGridState: updateGridState,
      },
    };
  }, [gridState]);

  const getRowStyle = (params) => {
    if (params.node && params.node.editing) {
      return { backgroundColor: "#000000" };
    }
    return null;
  };

  const gridData = useMemo(() => {
    let filteredData = sellTradeHistory || [];
    setGridState({
      ...gridState,
      pageIndex: paginationData?.pageNumber || defaultGridState?.pageIndex,
      totalRecords:
        paginationData?.totalRecords || defaultGridState?.totalRecords,
      pageEndRecordNumber:
        paginationData?.pageEndRecordNumber ||
        defaultGridState?.pageEndRecordNumber,
      pageStartRecordNumber:
        paginationData?.pageStartRecordNumber ||
        defaultGridState?.pageStartRecordNumber,
    });
    setIsSearchLoading(false);
    return filteredData;
  }, [sellTradeHistory]);

  function actionCellRenderer(params) {
    if (params.data?.id) {
      return (
        <div>
          <button
            className="action-Button-Wrapper ps-0 pe-0"
            onClick={() => {
              handleViewCart(params.data);
            }}
          >
            <ShowIcon className="cursor-pointer text-primary history-view-icon" />
          </button>
        </div>
      );
    } else {
      return <DropdownSkeleton width="5em" height="1.5em" />;
    }
  }

  const HistoryColDefs = useMemo(
    () => [
      {
        headerName: "Sale ID",
        field: "saleId",
        cellRenderer: (params) =>
          params?.data?.id ? (
            `${params.data.id}`
          ) : (
            <DropdownSkeleton width="5em" height="1.5em" />
          ),
      },
      {
        headerName: "Date",
        field: "createdOn",
        cellStyle: {
          overflow: "visible",
        },
        cellRenderer: (params) =>
          params?.data?.createdOn ? (
            <span className="inventory-cell-max-size ">
              <TableCustomHeader
                id={`created-on-in-array-${params?.data?.id}`}
                label={
                  <span className="inventory-cell-max-size">
                    {getReceiptPrintDate(params.data.createdOn)}
                  </span>
                }
                tooltopText={
                  <p className="text-start fw-normal m-0">
                    {getReceiptPrintDate(params.data.createdOn)}
                  </p>
                }
                className="sku-tooltip"
              />
            </span>
          ) : (
            <DropdownSkeleton width="5em" height="1.5em" />
          ),
      },
      {
        headerTooltip: "Transaction Type",
        headerName: "Transactio...",
        field: "TransactionType",
        cellRenderer: (params) =>
          params?.data?.type ? (
            `${params.data.type}`
          ) : (
            <DropdownSkeleton width="5em" height="1.5em" />
          ),
      },
      {
        headerName: "Payment",
        field: "paymentType",
        cellRenderer: (params) =>
          params?.data?.PaymentType ? (
            <span className="inventory-cell-max-size ">
              {params?.data?.PaymentType === transactionConstants.CREDIT_CARD
                ? buttonNameConstants.CREDIT_CARD
                : params?.data?.PaymentType === transactionConstants.GIFT_CARD
                ? buttonNameConstants.GIFT_CARD_PAYMENT
                : params?.data?.PaymentType ===
                  transactionConstants.STORE_CREDIT
                ? buttonNameConstants.STORE_CREDIT
                : buttonNameConstants.CASH}
            </span>
          ) : (
            <DropdownSkeleton width="5em" height="1.5em" />
          ),
      },
      {
        headerName: "Cart QTY",
        field: "cartQuantity",
        cellRenderer: (row) => {
          return row?.data?.inventory ? (
            row?.data?.inventory?.reduce((a, b) => a + Number(b.quantity), 0) ||
              row?.data?.inventory?.reduce(
                (a, b) => a + Number(b.price.quantity),
                0
              ) ||
              0
          ) : (
            <DropdownSkeleton width="5em" height="1.5em" />
          );
        },
      },
      {
        headerName: "Customer",
        field: "customer",
        cellRenderer: (params) =>
          params?.data?.customer ? (
            <span className="inventory-cell-max-size">
              {params?.data?.customer?.name ?? globalConstants.EMPTY_STRING}
            </span>
          ) : (
            <DropdownSkeleton width="5em" height="1.5em" />
          ),
      },
      {
        headerName: "Amount",
        field: "amount",
        cellRenderer: (params) =>
          params?.data?.totalAmountToPay ||
          params?.data?.totalAmountToPay === 0 ? (
            `$${parseToThousandSeperatorDecimalNumber(
              params?.data?.totalAmountToPay
            )}`
          ) : (
            <DropdownSkeleton width="5em" height="1.5em" />
          ),
      },
      {
        maxWidth: 150,
        headerName: "",
        field: "id",
        cellStyle: {
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          overflow: "visible",
        },
        lockPosition: "right",
        cellRenderer: actionCellRenderer,
        sortable: false,
      },
    ],
    // eslint-disable-next-line
    []
  );

  //-------Handle searchfield change
  const handleFilterData = (e) => {
    const filterList = sellTradeHistory.filter((row) => {
      return (
        String(
          row.type +
            row.id +
            row.PaymentType +
            row.TotalAmountPaid +
            row.customer.name +
            formatDate(row.createdOn)
        )
          .toLowerCase()
          .includes(String(searchText).toLowerCase()) &&
        searchText !== globalConstants.EMPTY_STRING
      );
    });
    // reset the pagination
    return filterList;
  };

  const filterColumnforExport = (allData) => {
    return allData.map((row) => {
      const cartQuantity =
        row.inventory?.reduce(
          (acc, curr) =>
            acc + Number(curr.quantity || curr.price?.quantity || 0),
          0
        ) || 0;
      const exportObject = {
        SaleId: row.id,
        CreatedOn: row.createdOn,
        TransactionType: row.type,
        PaymentType: row.PaymentType,
        CartQuantity: cartQuantity,
        Customer: row.customer.name,
        TotalAmountPaid: row.totalAmountToPay,
      };
      return exportObject;
    });
  };
  const handleExportButtonClick = () => {
    downloadCSV(
      searchText
        ? filterColumnforExport(handleFilterData())
        : filterColumnforExport(sellTradeHistory)
    );
  };
  const toggleCustomTradeModal = () => {
    setShow(!show);
    setIsAddCustomer(!isAddCustomer);
  };
  const toggleIsReceiptModal = () => {
    setIsReceiptModal(!isReceiptModal);
  };
  const handleIssueRefund = () => {
    navigate(routesPathConstants.SELL_AND_TRADE_NEW_RETURN, {
      state: {
        id: viewCartDetail[0]?.id,
      },
    });
  };

  const handlePrintReceiptTrade = (newTradeData) => {
    const partialPayment =
      (newTradeData?.paymentHistory?.[transactionConstants.CASH] || 0) +
        (newTradeData?.paymentHistory?.[transactionConstants.STORE_CREDIT] ||
          0) >
        0 &&
      newTradeData?.totalAmountToPay !== newTradeData?.currentAmountToPay;
    const printData = prepareReceiptForPrinterTrade(
      user,
      newTradeData?.id,
      newTradeData?.paymentHistory,
      currentStore,
      newTradeData?.customer,
      [
        ...newTradeData?.inventory.map((inv) => {
          return {
            ...inv,
            cogs: inv?.price?.unit_purchase_price,
            item: inv?.product_name,
            priceAfterDiscount: partialPayment
              ? getUnitPurchasePrice(newTradeData, inv)
              : inv?.price?.unit_purchase_price,
            itemName: inv?.product_name,
            quantity: inv?.price?.quantity,
            productType: inv?.productType,
            category: inv?.category_name,

            // max: 2,
            // actualPrice: inv?.price?.unit_sell_price,
            // isDiscountApplied: false,
            // discountPercentage: 0,
            // priceAfterDiscount: 1000,
            // actualDiscountPercentage: 0,
          };
        }),
      ],
      newTradeData?.discount,
      newTradeData?.SubTotal,
      newTradeData?.Tax,
      newTradeData?.totalAmountToPay,
      0,
      0,
      false,
      partialPayment,
      false,
      getReceiptPrintDate(newTradeData.createdOn)
    );
    printReceiptData(printData, defaultReceiptPrinter);
  };
  const handlePrintReceipt = (newTradeData) => {
    if (idType == globalConstants.TYPE_ID_TRD) {
      handlePrintReceiptTrade(newTradeData);
    } else {
      const printData = prepareReceiptForPrinter(
        user,
        newTradeData.id,

        newTradeData.paymentHistory,
        currentStore,
        newTradeData.customer,
        [
          ...newTradeData?.inventory?.map((inv) => {
            return {
              ...inv,
              cogs: inv?.price?.unit_purchase_price,
              item: inv?.product_name,
              priceAfterDiscount: inv?.price?.unit_purchase_price,
              itemName: inv?.product_name,
              quantity: inv?.price?.quantity,
              productType: inv?.productType,
              category: inv?.category_name,
              // max: 2,
              // actualPrice: inv?.price?.unit_sell_price,
              // isDiscountApplied: false,
              // discountPercentage: 0,
              // priceAfterDiscount: 1000,
              // actualDiscountPercentage: 0,
            };
          }),
        ],
        newTradeData.discount,
        newTradeData.SubTotal,
        newTradeData.Tax,
        newTradeData.totalAmountToPay,
        newTradeData.cashTenderedAmount,
        newTradeData.cashChangeOwed,
        newTradeData.giftCardPaymentHistory,
        false,
        false,
        newTradeData.discountMethod,
        newTradeData.discountedValue,
        getReceiptPrintDate(newTradeData.createdOn)
      );
      printReceiptData(printData, defaultReceiptPrinter);
    }
  };
  const handlePrintProductLabel = () => {
    toggleCustomTradeModal();
    togglePrintLabelModal();
  };
  //-------Toggle Print Label Modal
  const togglePrintLabelModal = () => {
    setPrintLabelModal(!printLabelModal);
  };
  const getTimeFrame = (value) => {
    setTimeFrame({
      ...timeFrame,
      start: moment.utc(value.start).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      end: moment.utc(value.end).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      duration: value?.duration,
    });
  };
  const clearSearchText = () => {
    setSearchText("");
    setDebouncedSearchText("");
    updateGridState({ ...gridState, searchQuery: "" });
  };

  const handleAddCustomerClick = () => {
    setIsAddCustomer(!isAddCustomer);
  };

  const selectedCustomer = (selectedCustomerFlag) => {
    setSelectedCustomerFlag(selectedCustomerFlag);
  };

  const getRowId = useCallback((params) => params.data.id, []);

  const components = useMemo(() => {
    return {
      agColumnHeader: CustomGridHeader,
    };
  }, []);

  const handleUpdateCustomer = (customer) => {
    const transactionType = isTradeTransaction(viewCartDetail[0])
      ? "trade"
      : isSaleTransaction(viewCartDetail[0])
      ? "transaction"
      : "return";

    const customerData = customer?.id ? customer : { name: "", id: "" };
    const { id, store } = viewCartDetail[0] || {};
    const transactionDetails = {
      id,
      storeId: store?.id,
      type: option?.value,
      start: timeFrame.start,
      end: timeFrame.end,
    };

    updateCustomerInTransactionHistory(
      { customer: customerData },
      transactionDetails,
      gridState,
      transactionType
    );
  };

  const handleDebouncedSearch = useDebouncedCallback((value) => {
    // setSearchText(value);
    updateGridState({ ...gridState, searchQuery: value, pageIndex: 1 });
  }, 1000);

  const handleSearchInputChange = (value) => {
    setIsSearchLoading(true);
    setDebouncedSearchText(value);
    handleDebouncedSearch(value);
  };

  useEffect(() => {
    if (viewCartDetail[0]?.id) {
      setViewCartDetail(
        sellTradeHistory?.filter((item) => item.id === viewCartDetail[0]?.id)
      );
    }
  }, [sellTradeHistory]);

  useEffect(() => {
    setCustomersList(
      allCustomers?.map((customer) => ({
        value: stringifyObject({
          firstName: customer.firstName,
          lastName: customer.lastName,
          email: customer.email,
          mobile: customer.mobile,
          currentBalance: customer.currentBalance,
          store: {
            id: currentStore?.id,
            name: currentStore?.id,
          },
          id: customer.id,
          name: `${customer.firstName} ${customer.lastName}`,
        }),
        label: (
          <div className="d-flex align-items-center gap-2 ">
            <span>
              <img
                src={CustomerImage}
                className="new-sale-customer-image"
                alt="customer"
              />
            </span>
            {`${customer.firstName} ${customer.lastName}`}
          </div>
        ),
      }))
    ); // eslint-disable-next-line
  }, [allCustomers]);
  useEffect(() => {
    setAddCustomerModal(isAddCustomerModal);
    // eslint-disable-next-line
  }, [isAddCustomerModal]);

  useEffect(() => {
    if (currentStore && timeFrame.start && timeFrame.end) {
      getAllTransaction(
        currentStore?.id,
        option?.value,
        timeFrame.start,
        timeFrame.end,
        gridState
      );
    }
  }, [timeFrame.start, timeFrame.end, currentStore, option]);

  useEffect(() => {
    if (allCustomers && allCustomers.length > 0 && selectedCustomerFlag) {
      handleUpdateCustomer({
        ...allCustomers[0],
        name: allCustomers[0].firstName + " " + allCustomers[0].lastName,
      });
    }
    setSelectedCustomerFlag(false);
    // eslint-disable-next-line
  }, [allCustomers]);

  const skeletonArray = Array(10).fill({});
  //-------Return History Component
  return (
    <>
      <Topbar
        handleClick={() => handleExportButtonClick()}
        exportButton={true}
        IconImage={ExportCSVIcon}
        label={buttonNameConstants.EXPORT_CSV}
        title="Transaction History"
        importCsvButton={true}
        handleImportCsvClick={() => toggleImportTransactionHistoryModal()}
      ></Topbar>
      <Row className="m-0 ">
        <Col
          md={12}
          className="transaction-overview-card-wrapper d-flex flex-wrap gap-3"
        >
          {!isSpinnerEnabled(
            spinnerArray,
            transactionConstants.GET_ALL_TRANSACTION
          ) ? (
            <>
              <TransactionOverViewCard
                label="Total Sold"
                data={`$${parseToThousandSeperatorDecimalNumber(
                  sellTradeStats?.totalSales ?? 0
                )}`}
              />

              <TransactionOverViewCard
                label="Total COGS"
                data={`$${parseToThousandSeperatorDecimalNumber(
                  sellTradeStats?.totalCogs ?? 0
                )}`}
              />

              <TransactionOverViewCard
                label="Total Net"
                data={`$${parseToThousandSeperatorDecimalNumber(
                  sellTradeStats?.totalNet ?? 0
                )}`}
              />

              <TransactionOverViewCard
                label="Total Number of Transactions"
                data={parseToThousandSeperatorWholeNumber(
                  sellTradeStats?.totalNumberOfTransactions
                )}
              />
            </>
          ) : (
            <>
              <SummaryCardSkeleton />
              <SummaryCardSkeleton />
              <SummaryCardSkeleton />
              <SummaryCardSkeleton />
            </>
          )}
        </Col>
        <Col md={12} className="d-flex align-items-center mb-2 gap-3">
          <div className={`w-100 d-flex align-items-center gap-3 `}>
            <DatetimePickerDropdown label="Date" getTimeFrame={getTimeFrame} />{" "}
            <CategoriesDropdown
              options={inventoryConstants.TRANSACTION_TYPE}
              label="Type"
              handleChange={(e) => {
                setOption(
                  e == null
                    ? {
                        label: globalConstants.ALL,
                        value: globalConstants.ALL,
                      }
                    : e
                );
              }}
              noOptionsMessage="No Transaction Type Found"
              placeholder="Transaction Type"
              SelectIconImage={categoryDropdownConstants.TRANSACTION_ICON}
              className={"dropdown-max-width"}
              isClearable={false}
              defaultValue={{
                value: PRODUCT_TYPES_ENUMS.ALL,
                label: "All",
              }}
            />
            <SearchField
              onChange={(e) => handleSearchInputChange(e.target.value)}
              IconImage={SearchIcon}
              BlueBoxIcon={BlueBoxIcon}
              label="Search"
              placeHolder="Type or Scan"
              value={debouncedSearchText}
              CrossIcon={debouncedSearchText ? CrossIcon : ""}
              clearSearchText={clearSearchText}
              LoadingIndicator={
                isSearchLoading ||
                (isButtonDisable(
                  spinnerArray,
                  inventoryConstants.GET_ALL_TRANSACTION
                ) &&
                  searchText)
                  ? LoadingIndicator
                  : false
              }
            />
          </div>
        </Col>
      </Row>
      <Row className="m-0 w-100 h-100 user-screen-wrapper pt-3 p-0">
        <ServerPaginationTable
          colDefs={HistoryColDefs}
          gridRef={gridRef}
          gridData={
            isSpinnerEnabled(
              spinnerArray,
              inventoryConstants.GET_INVENTORY_HISTORY
            )
              ? skeletonArray
              : gridData
          }
          gridState={gridState}
          getRowId={getRowId}
          components={components}
          getRowStyle={getRowStyle}
          defaultColDef={defaultColDef}
          updateGridState={updateGridState}
          paginationPageSize={gridState.pageSize}
          pageSizeOptions={gridState.pageSizeOptions}
          isDisablePaginationActions={isSpinnerEnabled(
            spinnerArray,
            inventoryConstants.GET_INVENTORY_HISTORY
          )}
          tableHeight={`calc(100vh - 320px)`}
        />
      </Row>
      <ImportTransactionHistory
        currentStore={currentStore}
        emptyDuplicateRecords={emptyDuplicateRecords}
        duplicateHistoryRecords={duplicateHistoryRecords}
        bulkUploadTransactionHistory={bulkUploadTransactionHistory}
        importTransactionHistoryModal={importTransactionHistoryModal}
        toggleImportTransactionHistoryModal={
          toggleImportTransactionHistoryModal
        }
      />
      <Modal
        show={show}
        onHide={toggleCustomTradeModal}
        size="md"
        animation={true}
        centered
        backdrop="static"
        className="add-inventory-modal"
      >
        <Form className="px-0">
          <Modal.Header closeButton>
            <Modal.Title className="d-flex align-items-center">
              <span className="modal-sale-transaction-id">
                {isTradeTransaction(viewCartDetail?.[0])
                  ? "Trade "
                  : isReturnTransaction(viewCartDetail?.[0])
                  ? "Return "
                  : "Sale "}
                ({viewCartDetail?.[0]?.id})
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="pt-0">
            <Row className="m-0 p-0">
              <Col md={12} className="p-0 mb-2">
                <span className="trans-items-modal view-cart-date">
                  {getReceiptPrintDate(viewCartDetail?.[0]?.createdOn)}
                </span>
              </Col>
              <Col md={12} className="p-0 mb-4 mt-2">
                {Array.isArray(viewCartDetail) &&
                viewCartDetail[0]?.customer?.id ? (
                  <div className="selected-customer-wrapper d-flex justify-content-start gap-3 align-items-center">
                    <img src={CustomerImage} alt="customer" />
                    <div className="d-flex flex-column justify-content-center selected-customer-wrapper ">
                      <span className="trans-product-name-modal-total">{`${
                        viewCartDetail[0]?.customer?.firstName || "Customer"
                      } ${
                        viewCartDetail[0]?.customer?.lastName || "Name"
                      }`}</span>
                      <span>
                        {viewCartDetail[0]?.customer?.mobile ||
                          "(000) 555-0101"}
                      </span>
                    </div>

                    <button
                      type="button"
                      className="cart-card-delete-button ms-auto p-0 opacity-50"
                      onClick={() => {
                        if (
                          viewCartDetail[0]?.paymentHistory?.totalPaidAmount
                            ?.storeCredit > 0
                        ) {
                          customToast(
                            toastMessages.UPDATE_CUSTOMER_ERROR,
                            toastType.ERROR
                          );
                        } else {
                          handleUpdateCustomer(false);
                        }
                      }}
                    >
                      <DeleteIcon />
                    </button>
                  </div>
                ) : !isAddCustomer ? (
                  <Button
                    className="p-0"
                    type="button"
                    label={buttonNameConstants.ADD_CUSTOMER}
                    buttonType={buttonTypeConstants.TRANSPARENT_BUTTON_PURPLE}
                    handleClick={handleAddCustomerClick}
                  />
                ) : (
                  <CustomerDropdown
                    options={customersList}
                    value={""}
                    menuPortalTarget=""
                    dropdownIndicator={false}
                    noOptionsMessage="No Customer Found"
                    placeholder="Customer Name, Phone, Email"
                    handleAddCustomerClick={handleAddNewCustomerClick}
                    SelectIconImage={categoryDropdownConstants.SEARCH_ICON}
                    handleChange={(selectedOption) => {
                      if (selectedOption?.value) {
                        handleUpdateCustomer(
                          parseJsonObject(selectedOption?.value)
                        );
                      }
                    }}
                    selectedCustomerFlag={selectedCustomerFlag}
                  />
                )}
              </Col>
            </Row>
            <Row className="m-0 p-0 ">
              <div className="container-scrollbar p-0  trans-modal-card-wrapper ">
                {Array.isArray(viewCartDetail) &&
                  viewCartDetail[0]?.inventory?.map((product) => {
                    return (
                      <>
                        <Col
                          md={12}
                          className="m-0 p-0 mb-1 d-flex justify-content-between"
                        >
                          <div className="d-flex align-items-center gap-1">
                            <span className="trans-items-modal">
                              {product?.price?.quantity}
                            </span>
                            <span className="trans-product-name-modal">
                              {product.product_name}
                            </span>
                            <span className="trans-items-modal">
                              {product?.sku !==
                                transactionConstants.QUICK_TRADE &&
                                `#${getShortestSku(product?.sku)}`}
                            </span>
                          </div>
                          <div>
                            <span className="trans-product-name-modal">
                              $
                              {isTradeTransaction(viewCartDetail[0])
                                ? parseToThousandSeperatorDecimalNumber(
                                    product?.price?.unit_purchase_price
                                  )
                                : parseToThousandSeperatorDecimalNumber(
                                    product?.price?.unit_purchase_price
                                  )}
                            </span>
                          </div>
                        </Col>
                        <Col
                          md={12}
                          className="m-0 p-0 mb-3
                             d-flex justify-content-between"
                        >
                          <div className="d-flex align-items-center gap-1">
                            <span className="trans-items-modal ms-3">
                              {isTradeTransaction(viewCartDetail[0])
                                ? product?.["category_name"]
                                : product?.category}
                            </span>
                            <span className="trans-items-modal">
                              (
                              {isTradeTransaction(viewCartDetail[0])
                                ? product?.price?.type
                                : product?.type}
                              )
                            </span>
                            <span className="trans-items-modal">
                              {getProductDetailCardTags(
                                product.tags?.filter(
                                  (tag) =>
                                    tag !== inventoryConstants.CUSTOM_ITEM_TAG
                                ),
                                product.sku
                              )}
                            </span>
                          </div>
                        </Col>
                      </>
                    );
                  })}
              </div>
              <Col
                md={12}
                className="m-0 mt-2 mb-2 p-0 d-flex justify-content-between tran-modal-detail-margin"
              >
                <div className="trans-product-name-modal-text m-0">
                  Subtotal
                </div>

                <div className="">
                  {` $${parseToThousandSeperatorDecimalNumber(
                    viewCartDetail?.[0]?.SubTotal
                  )}`}
                </div>
              </Col>
              <>
                {
                  // discount is visible in case of sale transaction
                  isSaleTransaction(viewCartDetail?.[0]) && (
                    <Col
                      md={12}
                      className="m-0  mb-2 p-0 d-flex justify-content-between tran-modal-detail-margin"
                    >
                      <div className="trans-product-name-modal-text m-0">
                        Discount
                      </div>
                      <div className="">
                        {`$${parseToThousandSeperatorDecimalNumber(
                          viewCartDetail?.[0]?.discount
                        )}`}
                      </div>
                    </Col>
                  )
                }
                {
                  // tax is visible in case of sale and return transaction
                  (isSaleTransaction(viewCartDetail?.[0]) ||
                    isReturnTransaction(viewCartDetail?.[0])) && (
                    <Col
                      md={12}
                      className="m-0  mb-2 p-0 d-flex justify-content-between tran-modal-detail-margin"
                    >
                      <div className="trans-product-name-modal-text m-0">
                        {`Tax(
                  ${parseToDecimalNumber(
                    viewCartDetail?.[0]?.taxPercentage ?? 0
                  )}
                  %)`}
                      </div>
                      <div className="">
                        {`$${parseToThousandSeperatorDecimalNumber(
                          viewCartDetail?.[0]?.Tax ?? 0
                        )}`}
                      </div>
                    </Col>
                  )
                }
              </>

              <Col
                md={12}
                className="m-0  mb-2 p-0 pt-2 d-flex justify-content-between border-top tran-modal-detail-margin"
              >
                <div className="trans-product-name-modal-total m-0">
                  TOTAL ({calcullateItems(viewCartDetail?.[0]?.inventory)}{" "}
                  items)
                </div>

                <div className="trans-product-name-modal-total">
                  $
                  {parseToThousandSeperatorDecimalNumber(
                    viewCartDetail?.[0]?.totalAmountToPay
                  )}
                </div>
              </Col>
              {
                // PAYMENT HISTORY: history is generating in case of transaction (sale) and trade and in case of return use the current paid values as there is no split payment feature in return transaction
                isReturnTransaction(viewCartDetail?.[0]) ? (
                  <Col
                    md={12}
                    className="m-0 p-0 mb-1 d-flex justify-content-between"
                  >
                    <div className="d-flex align-items-center gap-1">
                      <span className="trans-product-name-modal-text">
                        {viewCartDetail?.[0].PaymentType ===
                        transactionConstants.CREDIT_CARD
                          ? buttonNameConstants.CREDIT_CARD
                          : viewCartDetail?.[0].PaymentType ===
                            transactionConstants.STORE_CREDIT
                          ? buttonNameConstants.STORE_CREDIT
                          : viewCartDetail?.[0].PaymentType ===
                            transactionConstants.GIFT_CARD
                          ? viewCartDetail?.[0]?.giftCard?.giftCardName
                          : buttonNameConstants.CASH}
                      </span>
                    </div>
                    <div>
                      <span className="trans-product-name-modal-text">
                        $
                        {parseToThousandSeperatorDecimalNumber(
                          viewCartDetail?.[0]?.totalAmountToPay
                        )}
                      </span>
                    </div>
                  </Col>
                ) : (
                  viewCartDetail?.[0]?.paymentHistory?.history?.map(
                    (history) => {
                      return history.isUndoTransaction ? (
                        <Col
                          md={12}
                          className="m-0 p-0 mb-1 d-flex justify-content-between"
                        >
                          <div className="d-flex align-items-center gap-1">
                            <span className="trans-product-name-modal-text">
                              {history.paymentType ===
                              transactionConstants.CREDIT_CARD
                                ? buttonNameConstants.CREDIT_CARD
                                : history.paymentType ===
                                  transactionConstants.STORE_CREDIT
                                ? buttonNameConstants.STORE_CREDIT
                                : history.paymentType ===
                                  transactionConstants.GIFT_CARD
                                ? history?.giftCardName
                                : buttonNameConstants.CASH}
                            </span>
                          </div>
                          <div>
                            <span className="trans-product-name-modal-text">
                              $
                              {parseToThousandSeperatorDecimalNumber(
                                history?.currentAmountToPay
                              )}
                            </span>
                          </div>
                        </Col>
                      ) : (
                        ""
                      );
                    }
                  )
                )
              }
            </Row>
            {viewCartDetail?.[0]?.note && (
              <Col md={12} className="m-0 mt-3   p-0">
                <div className="note-text-div container-scrollbar">
                  <p className="trans-items-modal mb-2">Note</p>
                  <span className="note-text">{viewCartDetail?.[0]?.note}</span>
                </div>
              </Col>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex justify-content-center gap-2 w-100">
              <Button
                type="button"
                label={buttonNameConstants.CLOSE}
                handleClick={() => toggleCustomTradeModal()}
                buttonType={buttonTypeConstants.GHOST_BUTTON}
                className="w-100"
              />
              <SelectDropDown
                outerWrapperClassname="w-100"
                menuPortalTarget=""
                isSingleValue="Receipt"
                placeHolder="Receipt"
                handleChange={(option) => {
                  if (
                    option?.value ===
                    transactionConstants.RECEIPT_OPTIONS[0].value
                  ) {
                    handlePrintReceipt(printRecieptData);
                  } else if (
                    option?.value ===
                    transactionConstants.RECEIPT_OPTIONS[1].value
                  ) {
                    toggleIsReceiptModal();
                  }
                }}
                styles={viewCartPrintDropdownStyle}
                menuPlacement="bottom"
                options={transactionConstants.RECEIPT_OPTIONS}
              />

              {viewCartDetail?.[0]?.id.substring(0, 3) ===
              globalConstants.TYPE_ID_TRA ? (
                <Button
                  type="button"
                  className="w-100"
                  label={buttonNameConstants.ISSUE_REFUND}
                  handleClick={handleIssueRefund}
                />
              ) : viewCartDetail?.[0]?.id.substring(0, 3) ===
                globalConstants.TYPE_ID_TRD ? (
                <Button
                  type="button"
                  className="w-100"
                  label={buttonNameConstants.PRINT_PRODUCT_LABEL}
                  handleClick={handlePrintProductLabel}
                ></Button>
              ) : (
                ""
              )}
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
      <PrintLabelModal
        printLabelModal={printLabelModal}
        togglePrintLabelModal={togglePrintLabelModal}
        selectedRows={selectedRows}
        // singlePrintArray={singlePrintArray}
      />
      <SendReceiptModal
        sendEmailOrSMS={sendEmailOrSMS}
        transactionData={viewCartDetail?.[0]}
        isReceiptModal={isReceiptModal}
        toggleIsReceiptModal={toggleIsReceiptModal}
      />
      <AddNewCustomerModal
        selectedCustomer={selectedCustomer}
        addCustomerModal={addCustomerModal}
      />
    </>
  );
}; //-------History component end here

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  printer: state.printer,
  customer: state.customer,
  inventory: state.inventory,
  transaction: state.transaction,
  user: state.authentication.user,
  currentStore: state.store.currentStore,
  spinnerArray: state.system.spinnerArray,
  isAddCustomerModal: state.transaction.isAddCustomerModal,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getAllTransaction: (storeId, type, start, end, gridState) =>
    dispatch(
      transactionActions.getAllTransaction(storeId, type, start, end, gridState)
    ),
  bulkUploadTransactionHistory: (storeId, data) =>
    dispatch(transactionActions.bulkUploadTransactionHistory(storeId, data)),
  emptyDuplicateRecords: () =>
    dispatch(transactionActions.emptyDuplicateRecords()),
  sendEmailOrSMS: (data) => dispatch(transactionActions.sendEmailOrSMS(data)),
  updateCustomerInTransactionHistory: (
    data,
    transactionDetails,
    gridState,
    endPoint
  ) =>
    dispatch(
      transactionActions.updateCustomerInTransactionHistory(
        data,
        transactionDetails,
        gridState,
        endPoint
      )
    ),
  toggleAddCustomerModal: (value) =>
    dispatch(transactionActions.toggleAddCustomerModal(value)),
});

//-------Export History Component
export default connect(mapStateToProps, mapDispatchToProps)(History);
