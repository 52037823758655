import { PRODUCT_TYPES_ENUMS } from "../system/globalEnums";

//-------Export Global Constants
export const globalConstants = {
  DAILY: "Daily",
  MONTHLY: "Monthly",
  YEARLY: "Yearly",
  CUSTOM: "Custom",
  ALL: "All",

  EMPTY_STRING: "",
  EMPTY_OBJECT: {},
  REQUEST: "_REQUEST",
  SUCCESS: "_SUCCESS",
  FAILURE: "_FAILURE",
  SORT: {
    ASC: "asc",
    DESC: "desc",
  },
  DRAFT: "Draft",
  CASH: "cash",
  CREDIT: "storeCredit",
  GIFT_CARD: "giftCard",
  TYPE_ID_TRD: "TRD",
  TYPE_ID_TRA: "TRA",
  TYPE_RETURN: "RET",
  DEFAULT_ENDDATE: "2099-12-03T03:16:17.652Z",
  MAX_LENGTH_OF_PRODUCT_TITLE: 35,
  MAX_LENGTH_OF_PRODUCT_CATEGORY: 12,
};

export const gridSortingConstants = {
  DEFAULT: "",
  ACTIVE: "active",
  ASCENDING: "asc",
  DESCENDING: "desc",
  INACTIVE: "inactive",
};

export const countryCodesConstants = {
  USA: "US",
  CANADA: "CA",
};

export const defaultGridState = {
  pageIndex: 1,
  totalRecords: 0,
  searchQuery: "",
  pageEndRecordNumber: 0,
  pageStartRecordNumber: 0,
  pageSizeOptions: [10, 20, 50, 100, 500],
  sortFilter: globalConstants.EMPTY_STRING,
  pageSize: process.env.REACT_APP_GRID_PAGE_SIZE,
  filters: "",
};

export const defaultSaleProductsState = {
  pageIndex: 1,
  totalRecords: 0,
  searchQuery: "",
  pageEndRecordNumber: 0,
  pageStartRecordNumber: 0,
  sortFilter: globalConstants.EMPTY_STRING,
  category: globalConstants.EMPTY_STRING,
  productType: globalConstants.EMPTY_STRING,
  subcategory: globalConstants.EMPTY_STRING,
  pageSize: process.env.REACT_APP_GRID_PAGE_SIZE,
};

export const IMAGE_FILE_TYPES = {
  PNG: "image/png",
  JPG: "image/jpg",
  JPEG: "image/jpeg",
};
