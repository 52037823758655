import { globalConstants } from "./global.constant";
import {
  ACTIVITY_TYPES_ENUMS,
  PRODUCT_TYPES_ENUMS,
  PRODUCT_CONDITIONS_ENUMS,
  TRADING_CARD_CATEGORIES_ENUMS,
} from "../system/globalEnums";

//-------Export Inventory Constants
export const inventoryConstants = {
  SET_IS_CONSOLIDATION_MODAL: "SET_IS_CONSOLIDATION_MODAL",
  SET_CONSOLIDATION_MODAL: "SET_CONSOLIDATION_MODAL",
  DEFAULT_OFFER_VALUE: 0.01,
  EMPTY_THE_OBJECT: "",
  GET_TAGS: "GET_TAGS",
  PAL_CATEGORY: "PAL ",
  JP_CATEGORY: "JP ",
  GET_PRODUCT_IMAGE: "GET_PRODUCT_IMAGE",
  BULK_UPDATE: "BULK_UPDATE",
  CUSTOM_ITEM_TAG: "#Custom",
  POKEMON_API: "POKEMON_API",
  CUSTOM_ITEM: "CUSTOM_ITEM",
  SCRYFALL_API: "SCRYFALL_API",
  UPLOAD_IMAGE: "UPLOAD_IMAGE",
  ADD_INVENTORY: "ADD_INVENTORY",
  IMPORT_BATCH_INVENTORY_SIZE: 50,
  IMPORT_FAILED_BATCHES_RETRIES: 5,
  THRESHOLD_MAX_CASH_VALUE: 99999,
  AUTO_GENERATED: "Auto Generated",
  GET_CATEGORIES: "GET_CATEGORIES",
  GET_SKU_NUMBER: "GET_SKU_NUMBER",
  LABELS_PRINTING: "LABELS_PRINTING",
  CSV_IMPORT_FILE: "CSV_IMPORT_FILE",
  SPINE_BOX_LABEL: "SPINE_BOX_LABEL",
  EXPORT_FILE_NAME: "products-export",
  THRESHOLD_MAX_PERCENTAGE_VALUE: 100,
  TOGGLE_ADD_MODAL: "TOGGLE_ADD_MODAL",
  UPDATE_INVENTORY: "UPDATE_INVENTORY",
  SHRINK_INVENTORY: "SHRINK_INVENTORY",
  DELETE_INVENTORY: "DELETE_INVENTORY",
  PRICECHARTING_API: "PRICECHARTING_API",
  GET_ALL_INVENTORY: "GET_ALL_INVENTORY",
  INVENTORY_HISTORY: "INVENTORY_HISTORY",
  ADD_ITEM_IN_BATCH: "ADD_ITEM_IN_BATCH",
  SPINE_MEDIA_LABEL: " SPINE_MEDIA_LABEL",
  GET_PRICE_CHARTING: "GET_PRICE_CHARTING",
  ASIAN_ENGLISH_CATEGORY: "Asian English ",
  STANDARD_BOX_LABEL: "STANDARD_BOX_LABEL",
  SET_POKEMON_OBJECT: "SET_POKEMON_OBJECT",
  INITIAL_THRESHOLD_ID: "initialThresholdId",
  STANDARD_MEDIA_LABEL: " STANDARD_MEDIA_LABEL",
  CUSTOMER_EXPORT_FILE_NAME: "customers-export",
  GET_INVENTORY_HISTORY: "GET_INVENTORY_HISTORY",
  GET_PRICE_CHANGE_DATA: "GET_PRICE_CHANGE_DATA",
  IS_INVENTORY_IMPORTING: "IS_INVENTORY_IMPORTING",
  SET_ADD_BATCH_INVENTORY: "SET_ADD_BATCH_INVENTORY",
  SET_IS_BATCH_INVENTORY: "SET_IS_BATCH_INVENTORY",
  GET_POKEMON_PRODUCT_BY_ID: "GET_POKEMON_PRODUCT_BY_ID",
  GET_SCRYFALL_PRODUCT_BY_ID: "GET_SCRYFALL_PRODUCT_BY_ID",
  ADD_PRICE_CHANGE_THRESHOLD: "ADD_PRICE_CHANGE_THRESHOLD",
  GET_PRICE_CHANGE_THRESHOLD: "GET_PRICE_CHANGE_THRESHOLD",
  NINTENDO_CATEGORIES: ["Strategy Guide", "Nintendo Power"],
  SKYLANDER_CATEGORIES: [
    "Amiibo",
    "Skylanders",
    "Disney Infinity",
    "Lego Dimensions",
    "Starlink",
  ],
  EMPTY_INVENTORY_PRODUCTS_LIST: "EMPTY_INVENTORY_PRODUCTS_LIST",
  GET_PRICECHARTING_PRODUCT_BY_ID: "GET_PRICECHARTING_PRODUCT_BY_ID",
  GET_CATEGORIES_INVENTORY_HISTORY: "GET_CATEGORIES_INVENTORY_HISTORY",
  GET_INVENTORY_PRODUCT_BY_PRICECHARTING_ID:
    "GET_INVENTORY_PRODUCT_BY_PRICECHARTING_ID",
  GET_INVENTORY_PRODUCT_BY_CUSTOM_ITEM_CHECK:
    "GET_INVENTORY_PRODUCT_BY_CUSTOM_ITEM_CHECK",
  THRESHOLD: {
    BY_CATEGORY: 0,
    BY_PRICE_RANGE: 1,
    BY_PERCENTAGE: 0,
    BY_CASH: 1,
  },
  CUSTOM_PRODUCT_TYPES: [
    {
      value: PRODUCT_TYPES_ENUMS.VIDEO_GAME,
      label: "Video Games",
    },
    {
      value: PRODUCT_TYPES_ENUMS.TRADING_CARD,
      label: "Trading Cards",
    },
    {
      value: PRODUCT_TYPES_ENUMS.OTHER,
      label: "Other",
    },
  ],
  PRODUCT_TYPES: [
    {
      value: PRODUCT_TYPES_ENUMS.ALL,
      label: "All",
    },
    {
      value: PRODUCT_TYPES_ENUMS.VIDEO_GAME,
      label: "Video Games",
    },
    {
      value: PRODUCT_TYPES_ENUMS.TRADING_CARD,
      label: "Trading Cards",
    },
    {
      value: PRODUCT_TYPES_ENUMS.OTHER,
      label: "Other",
    },
  ],
  TRADING_CARD_CATEGORIES: [
    {
      value: TRADING_CARD_CATEGORIES_ENUMS.POKEMON_CARD,
      label: TRADING_CARD_CATEGORIES_ENUMS.POKEMON_CARD,
    },
    {
      value: TRADING_CARD_CATEGORIES_ENUMS.MAGIC_CARD,
      label: TRADING_CARD_CATEGORIES_ENUMS.MAGIC_CARD,
    },
    {
      value: TRADING_CARD_CATEGORIES_ENUMS.YUGIOH_CARD,
      label: TRADING_CARD_CATEGORIES_ENUMS.YUGIOH_CARD,
    },
  ],
  ACTIVITY_TYPE: [
    {
      value: ACTIVITY_TYPES_ENUMS.ALL,
      label: "All",
    },
    {
      value: ACTIVITY_TYPES_ENUMS.SALE,
      label: "Sale",
    },
    {
      value: ACTIVITY_TYPES_ENUMS.RETURN,
      label: "Return",
    },
    {
      value: ACTIVITY_TYPES_ENUMS.TRADE,
      label: "Trade",
    },
    {
      value: ACTIVITY_TYPES_ENUMS.ADD,
      label: "Add",
    },
    {
      value: ACTIVITY_TYPES_ENUMS.DELETE,
      label: "Delete",
    },
  ],
  MARGIN_TYPE_GLOBAL: {
    value: "Global",
    label: "Global",
  },

  MARGIN_TYPE_STANDARD: {
    value: "Standard",
    label: "Custom",
  },

  // Assuming this structure for category-specific margins
  CATEGORY_SPECIFIC_MARGINS: {
    "Nintendo Switch": 15, // 15% margin for electronics
    clothing: 20, // 20% margin for clothing
    // other categories...
  },
  TRANSACTION_TYPE: [
    {
      value: ACTIVITY_TYPES_ENUMS.ALL,
      label: "All",
    },
    {
      value: ACTIVITY_TYPES_ENUMS.SALE,
      label: "Sale",
    },
    {
      value: ACTIVITY_TYPES_ENUMS.RETURN,
      label: "Return",
    },
    {
      value: ACTIVITY_TYPES_ENUMS.TRADE,
      label: "Trade",
    },
  ],
  VIDEO_GAME_CONDITION: [
    {
      value: PRODUCT_CONDITIONS_ENUMS.COMPLETE,
      label: PRODUCT_CONDITIONS_ENUMS.COMPLETE,
    },
    {
      value: PRODUCT_CONDITIONS_ENUMS.LOOSE,
      label: PRODUCT_CONDITIONS_ENUMS.LOOSE,
    },
    {
      value: PRODUCT_CONDITIONS_ENUMS.NEW,
      label: PRODUCT_CONDITIONS_ENUMS.NEW,
    },
    {
      value: PRODUCT_CONDITIONS_ENUMS.BOX_ONLY,
      label: PRODUCT_CONDITIONS_ENUMS.BOX_ONLY,
    },
    {
      value: PRODUCT_CONDITIONS_ENUMS.MANUAL_ONLY,
      label: PRODUCT_CONDITIONS_ENUMS.MANUAL_ONLY,
    },
  ],
  VIDEO_GAME_SYSTEM_CONDITION: [
    {
      value: PRODUCT_CONDITIONS_ENUMS.COMPLETE,
      label: PRODUCT_CONDITIONS_ENUMS.COMPLETE,
    },
    {
      value: PRODUCT_CONDITIONS_ENUMS.LOOSE,
      label: PRODUCT_CONDITIONS_ENUMS.LOOSE,
    },
    {
      value: PRODUCT_CONDITIONS_ENUMS.NEW,
      label: PRODUCT_CONDITIONS_ENUMS.NEW,
    },
    {
      value: PRODUCT_CONDITIONS_ENUMS.BOX_ONLY,
      label: PRODUCT_CONDITIONS_ENUMS.BOX_ONLY,
    },
  ],
  TRADING_CARD_CONDITION: [
    {
      value: PRODUCT_CONDITIONS_ENUMS.NEAR_MINT,
      label: PRODUCT_CONDITIONS_ENUMS.NEAR_MINT,
    },
    {
      value: PRODUCT_CONDITIONS_ENUMS.LIGHTLY_PLAYED,
      label: PRODUCT_CONDITIONS_ENUMS.LIGHTLY_PLAYED,
    },
    {
      value: PRODUCT_CONDITIONS_ENUMS.MODERATELY_PLAYED,
      label: PRODUCT_CONDITIONS_ENUMS.MODERATELY_PLAYED,
    },
    {
      value: PRODUCT_CONDITIONS_ENUMS.HEAVILY_PLAYED,
      label: PRODUCT_CONDITIONS_ENUMS.HEAVILY_PLAYED,
    },
    {
      value: PRODUCT_CONDITIONS_ENUMS.DAMAGED,
      label: PRODUCT_CONDITIONS_ENUMS.DAMAGED,
    },
  ],
  NINTENDO_CONDITIONS: [
    {
      value: PRODUCT_CONDITIONS_ENUMS.COMPLETE,
      label: PRODUCT_CONDITIONS_ENUMS.WITH_POSTER,
    },
    {
      value: PRODUCT_CONDITIONS_ENUMS.LOOSE,
      label: PRODUCT_CONDITIONS_ENUMS.LOOSE,
    },
  ],
  SKYLANDERS_CONDITION: [
    {
      value: PRODUCT_CONDITIONS_ENUMS.NEW,
      label: PRODUCT_CONDITIONS_ENUMS.NEW,
    },
    {
      value: PRODUCT_CONDITIONS_ENUMS.LOOSE,
      label: PRODUCT_CONDITIONS_ENUMS.LOOSE,
    },
  ],
  OTHER_CONDITION: [
    {
      value: PRODUCT_CONDITIONS_ENUMS.USED,
      label: PRODUCT_CONDITIONS_ENUMS.USED,
    },
    {
      value: PRODUCT_CONDITIONS_ENUMS.NEW,
      label: PRODUCT_CONDITIONS_ENUMS.NEW,
    },
  ],
  KEY_CODE: {
    comma: 188,
    enter: 13,
  },
  TAG_SUGGESTIONS: [
    "missing-wires",
    "missing-controller",
    "with-box",
    "damaged",
    "missing-manual",
    "with-manual",
  ],
  PRODUCT_TAGS: {
    MISSING_MANUAL: "#missing-manual",
    WITH_MANUAL: "#with-manual",
    DAMAGED: "#damaged",
    WITH_BOX: "#with-box",
    MISSING_WIRES: "#missing-wires",
    MISSING_CONTROLLER: "#missing-controller",
  },
  priceRangeOptions: [
    { value: { min: 1, max: 50 }, label: "1 - 50" },
    { value: { min: 50, max: 100 }, label: "50 - 100" },
    { value: { min: 100, max: 500 }, label: "100 - 500" },
    { value: { min: 500, max: 1000 }, label: "500 - 1000" },
    { value: { min: 1000, max: 2000 }, label: "1000 - 2000" },
  ],
  INVALID_IMPORT_VALUES: {
    COMPLETE_IN_BOX: "completeinbox",
    CIB: "cib",
    COMPLETE: "complete",
    LOOSE: "loose",
    NEW: "new",
    VIDEOGAME: "videogame",
    TRADINGCARD: "tradingcard",
  },

  //-------Keys of Pricecharting Products Object Prices
  NEW_PRICE: "new-price",
  LOOSE_PRICE: "loose-price",
  GAMESTOP_PRICE: "gamestop-price",
  BOX_ONLY_PRICE: "box-only-price",
  COMPLETE_IN_BOX_PRICE: "cib-price",
  RETAIL_NEW_PRICE: "retail-new-sell",
  MANUAL_ONLY_PRICE: "manual-only-price",
  RETAIL_LOOSE_PRICE: "retail-loose-sell",
  PRICECHARTING_PRODUCT_NAME: "product-name",
  RETAIL_COMPLETE_IN_BOX_PRICE: "retail-cib-sell",
  PRICECHARTING_PRODUCT_CONSOLE_NAME: "console-name",
  IMPORT_INVENTORY_ERROR: "Something went wrong. Please try again.",

  //-------Tags Deducted Percentage from Market Price
  DAMAGED_PERCENTAGE: 0.3,
  MISSING_WIRES_PERCENTAGE: 0.15,
  MISSING_CONTROLLER_PERCENTAGE: 0.25,
  CONSOLIDATION_MODAL_DEFAULT_STATE: {
    isConsolidationModal: false,
    handleYesButtonClick: { functionAttributes: [], functionToCall: () => {} },
  },
  PRODUCT_UPDATED_SUCCESSFULLY: "Product updated successfully.",
};

//-------Default data object for inventory product
export const INVENTORY_PRODUCT_DEFAULT_DATA_OBJECT = {
  skuNumber: [],
  cardNumber: 0,
  costOfGoods: 0,
  marketPrice: 0,
  maxBuyPrice: 0,
  inStorePrice: 0,
  inStockPrice: 0,
  averageBuyPrice: 0,
  inStockQuantity: 0,
  gameStopBuyPrice: 0,
  averageSellPrice: 0,
  suggestedSellPrice: 0,
  upc: globalConstants.EMPTY_STRING,
  epid: globalConstants.EMPTY_STRING,
  genre: globalConstants.EMPTY_STRING,
  imgUrl: globalConstants.EMPTY_STRING,
  productId: globalConstants.EMPTY_STRING,
  productName: globalConstants.EMPTY_STRING,
  consoleName: globalConstants.EMPTY_STRING,
  tcgPlayerUrl: globalConstants.EMPTY_STRING,
};

export const BATCH_INVENTORY_DEFAULT_PRODUCT_OBJECT = {
  id: 0,
  tags: [],
  skuNumber: [],
  maxBuyPrice: 0,
  costOfGoods: 0,
  marketPrice: 0,
  inStorePrice: 0,
  inStockPrice: 0,
  cogsPercentage: 0,
  averageBuyPrice: 0,
  inStockQuantity: 1,
  averageSellPrice: 0,
  gameStopBuyPrice: 0,
  isDataFetching: false,
  suggestedSellPrice: 0,
  productsAvailableInInventory: [],
  upc: globalConstants.EMPTY_STRING,
  epid: globalConstants.EMPTY_STRING,
  genre: globalConstants.EMPTY_STRING,
  imgUrl: globalConstants.EMPTY_STRING,
  productId: globalConstants.EMPTY_STRING,
  consoleName: globalConstants.EMPTY_STRING,
  productName: globalConstants.EMPTY_STRING,
  tcgPlayerUrl: globalConstants.EMPTY_STRING,
};

export const MARGIN_TYPES = {
  GLOBAL: "GLOBAL",
  BY_CATEGORY: "BY_CATEGORY",
  BY_PRODUCT_TYPE: "BY_PRODUCT_TYPE",
};

export const GENRE_SUB_CATEGORY = {
  PRICECHARTING_GENRE: [
    {
      genre: ["Accessories", "Controllers", "Accessories & Controllers"],
      subCategory: "Accessories & Controllers",
    },
    {
      genre: ["Action & Adventure"],
      subCategory: "Action & Adventure",
    },
    {
      genre: ["Arcade"],
      subCategory: "Arcade",
    },
    {
      genre: ["Beat'em Up"],
      subCategory: "Beat'em Up",
    },
    {
      genre: ["Board & Card"],
      subCategory: "Board & Card",
    },
    {
      genre: ["Casino"],
      subCategory: "Casino",
    },
    {
      genre: ["Compilation"],
      subCategory: "Compilation",
    },
    {
      genre: ["Dance"],
      subCategory: "Dance",
    },
    {
      genre: ["Demo & NFR"],
      subCategory: "Demo & NFR",
    },
    {
      genre: ["Educational"],
      subCategory: "Educational",
    },
    {
      genre: ["Fighting"],
      subCategory: "Fighting",
    },
    {
      genre: ["FPS"],
      subCategory: "FPS",
    },
    {
      genre: ["Hack and Slash"],
      subCategory: "Hack and Slash",
    },
    {
      genre: ["Horror"],
      subCategory: "Horror",
    },
    {
      genre: ["Light Gun"],
      subCategory: "Light Gun",
    },
    {
      genre: ["Magazine"],
      subCategory: "Magazine",
    },
    {
      genre: ["Mini Games"],
      subCategory: "Mini Games",
    },
    {
      genre: ["Movie"],
      subCategory: "Movie",
    },
    {
      genre: ["Music"],
      subCategory: "Music",
    },
    {
      genre: ["Other"],
      subCategory: "Other",
    },
    {
      genre: ["Party"],
      subCategory: "Party",
    },
    {
      genre: ["Pinball"],
      subCategory: "Pinball",
    },
    {
      genre: ["Platformer"],
      subCategory: "Platformer",
    },
    {
      genre: ["Puzzle"],
      subCategory: "Puzzle",
    },
    {
      genre: ["Racing"],
      subCategory: "Racing",
    },
    {
      genre: ["RPG"],
      subCategory: "RPG",
    },
    {
      genre: ["Shoot'em Up"],
      subCategory: "Shoot'em Up",
    },
    {
      genre: [
        "Sports",
        "Football",
        "Basketball",
        "Golf",
        "Baseball",
        "Extreme Sports",
        "Soccer",
        "Wrestling",
      ],
      subCategory: "Sports",
    },
    {
      genre: ["Strategy"],
      subCategory: "Strategy",
    },
    {
      genre: ["Systems"],
      subCategory: "Systems",
    },
    {
      genre: ["Third Person Shooter"],
      subCategory: "Third Person Shooter",
    },
    {
      genre: ["Trivia"],
      subCategory: "Trivia",
    },
    {
      genre: ["Visual Novel"],
      subCategory: "Visual Novel",
    },
  ],
};
export const GET_INVENTORY_MISC_INFO_TYPES = {
  GET_PRODUCTS_BY_PRICECHARTING_ID: "GET_PRODUCTS_BY_PRICECHARTING_ID",
  GET_PRODUCTS_BY_META_DATA: "GET_PRODUCTS_BY_META_DATA",
  GET_PRODUCT_IMAGE_URL: "GET_PRODUCT_IMAGE_URL",
};
