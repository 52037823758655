export const GLOBAL_STATUS = {
  SUCCESS: 200,
  NOT_FOUND: 404,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  INTERNAL_SERVER_ERROR: 500,
};
export const PRODUCT_TYPES_ENUMS = {
  ALL: "All",
  VIDEO_GAME: "Video Game",
  TRADING_CARD: "Trading Card",
  OTHER: "Other",
  GIFT_CARD: "Gift Card",
};

export const PRODUCT_CONDITIONS_ENUMS = {
  COMPLETE: "Complete",
  LOOSE: "Loose",
  NEW: "New",
  BOX_ONLY: "Box Only",
  MANUAL_ONLY: "Manual Only",
  NEAR_MINT: "Near Mint (NM)",
  LIGHTLY_PLAYED: "Lightly Played (LP)",
  MODERATELY_PLAYED: "Moderately Played (MP)",
  HEAVILY_PLAYED: "Heavily Played (HP)",
  DAMAGED: "Damaged (D)",
  WITH_POSTER: "With Poster",
  USED: "Used",
};

export const TRADING_CARD_CATEGORIES_ENUMS = {
  POKEMON_CARD: "Pokemon Card",
  MAGIC_CARD: "Magic Card",
  YUGIOH_CARD: "YuGiOh Card",
  COMIC_BOOK: "Comic Book",
  SYSTEM: "Systems",
};
export const ACTIVITY_TYPES_ENUMS = {
  ALL: "All",
  SALE: "Sale",
  RETURN: "Return",
  TRADE: "Trade",
  ADD: "Add",
  DELETE: "Delete",
};

export const DATE_FORMAT = {
  DATE: "YYYY-MM-DD",
};

export const BILLING_TYPES_ENUMS = {
  MONTHLY: "Monthly",
  YEARLY: "Yearly",
};

export const PRICE_CHANGE_FILTER_ENUMS = {
  BY_CATEGORY_AND_VALUE: {
    value: 1,
    label: "By Category and Value",
  },
  BY_PRICE_RANGE_AND_VALUE: {
    value: 3,
    label: "By Price Range and Value",
  },
  BY_CATEGORY_AND_PERCENTAGE: {
    value: 0,
    label: "By Category and Percent",
  },
  BY_PRICE_RANGE_AND_PERCENTAGE: {
    value: 2,
    label: "By Price Range and Percent",
  },
};

export const GLOBAL_FILTER_ENUMS = {
  BY_PERCENTAGE: {
    value: 0,
    label: "Percentage",
  },
  BY_VALUE: {
    value: 1,
    label: "Value",
  },
};
export const CUSTOM_FILTER_ENUMS = {
  BY_CATEGORY: { value: 0, label: "Category" },
  BY_PRICE_RANGE: { value: 1, label: "Price Range" },
};

export const STORE_CARD_PAYMENT_TYPE_ENUMS = {
  VERIFONE: "VERIFONE",
  THIRD_PARTY: "THIRD_PARTY",
  NULL: "CARD_PAYMENT_NOT_USED",
};

export const SET_BY_MARGIN_ENUMS = {
  TYPE: { value: "BY_PRODUCT_TYPE", label: "Type" },
  GLOBAL: { value: "GLOBAL", label: "GLOBAL" },
  CATEGORY: { value: "BY_CATEGORY", label: "Category" },
  SUBCATEGORY: { value: "BY_SUBCATEGORY", label: "Subcategory" },
};

export const NOTIFICATION_TYPES = {
  CUSTOMER: "CUSTOMER",
  PRODUCT_LABEL: "PRODUCT_LABEL",
  PRODUCT_LABEL_EDIT: "PRODUCT_LABEL_EDIT",
  PRODUCT_LABEL_IMPORT: "PRODUCT_LABEL_IMPORT",
  PRODUCT_LABEL_EDIT_CONSOLIDATED: "PRODUCT_LABEL_EDIT_CONSOLIDATED",
};

export const EMAIL_MESSAGE_TYPES = {
  USER_REPORTED_ISSUE: "USER_REPORTED_ISSUE",
  TRANSACTION_RECEIPT: "TRANSACTION_RECEIPT",
};
